import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_PATH } from '../API';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faRedo, faTimes } from '@fortawesome/free-solid-svg-icons';

export const ClientForm = () => {

    const initialFormData = {
        name: '',
        contactNumber: '',
        mailId: '',
        sameAsContact: false,
        whatsappNumber: '',
        newsContent: [''],
        brand: [''],
        category: [''],
        price: '',
        postedOn: [''],
        postedBy: '',
        address: '',
        gst: '',
    };
    
    const [formData, setFormData] = useState(initialFormData);
    const [successMessage, setSuccessMessage] = useState('');
    const [adsData, setAdsData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {

        const adminName = sessionStorage.getItem('adminName');
        if (adminName) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                postedBy: adminName,
            }));
        }

        const fetchAdsData = async () => {
            try {
                const response = await axios.get(`${API_PATH}/client_datas/client_form.php`);
                setAdsData(response.data);
            } catch (error) {
                console.error('Error fetching ads data:', error);
            }
        };

        fetchAdsData();
    }, []);

    const handleChange = (e, index, field) => {
        const { name, value, type, checked, files } = e.target;
    
        if (type === 'checkbox') {
            setFormData({ ...formData, [name]: checked });
            if (name === 'sameAsContact' && checked) {
                setFormData({ ...formData, whatsappNumber: formData.contactNumber });
            }
        } else if (type === 'file') {
            const updatedFiles = [...formData[field]];
            updatedFiles[index] = files[0]; // Use files property for file inputs
            setFormData({ ...formData, [field]: updatedFiles });
        } else if (field) {
            const updatedFields = [...formData[field]];
            updatedFields[index] = value;
            setFormData({ ...formData, [field]: updatedFields });
        } else {
            setFormData({ ...formData, [name]: value });
    
            if (name === 'name' || name === 'contactNumber') {
                const matchingAd = adsData.find(
                    (ad) =>
                        ad.name.toLowerCase() === (name === 'name' ? value.toLowerCase() : formData.name.toLowerCase()) &&
                        ad.contact_number === (name === 'contactNumber' ? value : formData.contactNumber)
                );
    
                if (matchingAd) {
                    setFormData({
                        ...formData,
                        name: matchingAd.name,
                        contactNumber: matchingAd.contact_number,
                        mailId: matchingAd.mail_id,
                        whatsappNumber: matchingAd.whatsapp_number,
                        address: matchingAd.address,
                        gst: matchingAd.gst,
                    });
                }
            }
        }
    };
    

    const handleAddField = (field) => {
        setFormData({ ...formData, [field]: [...formData[field], ''] });
    };

    const handleRemoveField = (index, field) => {
        const updatedFields = [...formData[field]];
        updatedFields.splice(index, 1);
        setFormData({ ...formData, [field]: updatedFields });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataToSend = new FormData();
            Object.keys(formData).forEach((key) => {
                if (Array.isArray(formData[key])) {
                    formData[key].forEach((item, index) => {
                        if (item instanceof File) {
                            formDataToSend.append(`${key}[]`, item);
                        } else {
                            formDataToSend.append(`${key}[]`, item);
                        }
                    });
                } else if (formData[key] instanceof File) {
                    formDataToSend.append(key, formData[key]);
                } else {
                    formDataToSend.append(key, formData[key]);
                }
            });
    
            const response = await axios.post(`${API_PATH}/client_datas/client_form.php`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            if (response.data.status === 1) {
                setSuccessMessage('Record created successfully.');
                setTimeout(() => {
                    navigate('/client', { state: { successMessage: 'Record created successfully.' } });
                }, 1000);
            } else {
                setSuccessMessage('Failed to create record.');
            }
        } catch (error) {
            console.error('There was an error submitting the form!', error);
        }
    };
    

    const handleRefresh = () => {
        setFormData(initialFormData);
    };

    return (
        <div className="p-5">
            <div className="mb-3 d-flex justify-content-between">
                <button className="btn btn-outline-primary" onClick={() => navigate(-1)}>
                    <FontAwesomeIcon icon={faArrowLeft} /> Back
                </button>
                <button className="btn btn-outline-secondary" onClick={handleRefresh}>
                    <FontAwesomeIcon icon={faRedo} /> Refresh
                </button>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="contactNumber" className="form-label">Contact Number</label>
                    <input
                        type="text"
                        className="form-control"
                        id="contactNumber"
                        name="contactNumber"
                        value={formData.contactNumber}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="mailId" className="form-label">Mail ID</label>
                    <input
                        type="email"
                        className="form-control"
                        id="mailId"
                        name="mailId"
                        value={formData.mailId}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-check mb-3">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="sameAsContact"
                        name="sameAsContact"
                        checked={formData.sameAsContact}
                        onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="sameAsContact">
                        Contact number and Whatsapp number are the same
                    </label>
                </div>
                <div className="mb-3">
                    <label htmlFor="whatsappNumber" className="form-label">Whatsapp Number</label>
                    <input
                        type="text"
                        className="form-control"
                        id="whatsappNumber"
                        name="whatsappNumber"
                        value={formData.whatsappNumber}
                        onChange={handleChange}
                        required
                        disabled={formData.sameAsContact}
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">News Content</label>
                        {formData.newsContent.map((file, index) => (
                            <div key={index} className="d-flex align-items-center mb-2">
                                <input
                                    type="file"
                                    className="form-control mb-2"
                                    onChange={(e) => handleChange(e, index, 'newsContent')}
                                    accept=".jpg, .pdf"
                                    required
                                />
                                {index > 0 && (
                                    <button
                                        type="button"
                                        className="btn btn-danger ms-2"
                                        onClick={() => handleRemoveField(index, 'newsContent')}
                                    >
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                )}
                            </div>
                        ))}
                    <button type="button" className="btn btn-secondary mt-2" onClick={() => handleAddField('newsContent')}>
                        Add News Content
                    </button>
                </div>
                <div className="mb-3">
                    <label htmlFor="brand" className="form-label">Brand</label>
                    {formData.brand.map((brand, index) => (
                        <div key={index} className="d-flex align-items-center mb-2">
                            <input
                                key={index}
                                type="text"
                                className="form-control mb-2"
                                value={brand}
                                onChange={(e) => handleChange(e, index, 'brand')}
                                required
                            />
                            {index > 0 && (
                                <button
                                    type="button"
                                    className="btn btn-danger ms-2"
                                    onClick={() => handleRemoveField(index, 'brand')}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            )}
                        </div>
                    ))}
                    <button type="button" className="btn btn-secondary mt-2" onClick={() => handleAddField('brand')}>
                        Add Brand
                    </button>
                </div>
                <div className="mb-3">
                    <label htmlFor="category" className="form-label">Category</label>
                    {formData.category.map((category, index) => (
                        <div key={index} className="d-flex align-items-center mb-2">
                            <input
                                key={index}
                                type="text"
                                className="form-control mb-2"
                                value={category}
                                onChange={(e) => handleChange(e, index, 'category')}
                                required
                            />
                            {index > 0 && (
                                <button
                                    type="button"
                                    className="btn btn-danger ms-2"
                                    onClick={() => handleRemoveField(index, 'category')}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            )}
                        </div>
                    ))}
                    <button type="button" className="btn btn-secondary mt-2" onClick={() => handleAddField('category')}>
                        Add Category
                    </button>
                </div>
                <div className="mb-3">
                    <label htmlFor="price" className="form-label">Price</label>
                    <input
                        type="text"
                        className="form-control"
                        id="price"
                        name="price"
                        value={formData.price}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Posted On</label>
                    {formData.postedOn.map((date, index) => (
                        <div key={index} className="d-flex align-items-center mb-2">
                            <input
                                key={index}
                                type="date"
                                className="form-control mb-2"
                                value={date}
                                onChange={(e) => handleChange(e, index, 'postedOn')}
                                required
                            />
                            {index > 0 && (
                                <button
                                    type="button"
                                    className="btn btn-danger ms-2"
                                    onClick={() => handleRemoveField(index, 'postedOn')}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            )}
                        </div>
                    ))}
                    <button type="button" className="btn btn-secondary mt-2" onClick={() => handleAddField('postedOn')}>
                        Add Date
                    </button>
                </div>
                <div className="mb-3">
                    <label htmlFor="postedBy" className="form-label">Posted By</label>
                    <input
                        type="text"
                        className="form-control"
                        id="postedBy"
                        name="postedBy"
                        value={formData.postedBy}
                        readOnly
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="address" className="form-label">Address</label>
                    <input
                        type="text"
                        className="form-control"
                        id="address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="gst" className="form-label">GST</label>
                    <input
                        type="text"
                        className="form-control"
                        id="gst"
                        name="gst"
                        value={formData.gst}
                        onChange={handleChange}
                    />
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
                {successMessage && (
                    <div className="alert alert-success mt-3 text-center">
                        {successMessage}
                    </div>
                )}
            </form>
        </div>
    );
};

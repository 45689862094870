import React, { useEffect, useState } from 'react';
import './CSS/blog.css';
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

export const Blog = () => {

    const [rotationDinathathi, setRotationDinathathi] = useState(0);
    const [rotationTheHindu, setRotationTheHindu] = useState(0);
    const [rotation, setRotation] = useState(0);

        const handleDinathanthi = () => {
            setRotationDinathathi(rotationDinathathi + 180);
        };
        const handleTheHindu = () => {
            setRotationTheHindu(rotationTheHindu + 180);
        };
        const handleTimesOfIndia = () => {
            setRotation(rotation + 180);
        };

    useEffect(() =>{
        AOS.init();
    },[])
    return(
        <div>
            <Helmet>
                <title>Sri Sairam Advertising - Blog</title>
                <meta name="keywords" content="Blog, Sri Sairam Advertising, digital marketing tips, web design trends, graphic design inspiration, advertising insights, industry news, SEO strategies, content marketing, social media tips, creative ideas, branding trends"/>
                <meta name="description" content="Discover valuable tips, trends, and industry insights on our Sri Sairam Advertising blog. Stay informed and inspired!"/>
                <meta property="og:title" content="Insights & Inspiration: Sri Sairam Advertising Blog" />
                <meta property="og:url" content="https://srisairamadvertising.com/#/service/newspaper" />
                <meta property="og:description" content="Expand your brand's visibility with targeted newspaper ads in leading publications. Reach your audience effectively!" />
                <meta property="og:image" content={`https://srisairamadvertising.com/images/Card.png`}/>
                <link rel="canonical" href="https://srisairamadvertising.com/#/service/newspaper" />
            </Helmet>
            <div className="blog">
                <div className="blog-container small-content">
                    <div className="newspaper" style={{ transform: `rotateY(${rotationDinathathi}deg)`}} >
                        <div className="front-content">
                            <img src="./images/dinathanthi.png" alt="" />
                            <p>The Daily Thanthi, a journalistic powerhouse,......</p>
                            <button onClick={handleDinathanthi}>Know more <i><FontAwesomeIcon icon={faArrowUpRightFromSquare} fade style={{color: "#ff0000",}} /></i></button>
                            <div className="lineEffect"></div>
                        </div>
                        <div className="back-content">
                            <i onClick={handleDinathanthi} style={{cursor:"pointer"}}><FontAwesomeIcon icon={faCircleXmark} /></i>
                            <p>The Daily Thanthi, a journalistic powerhouse, proudly claims the No.1 spot in the state and a top 5 rank nationally, boasting a massive readership of 2.63 Crore. A trusted source for 37% of Tamil Nadu's population (7.14 Crore) and 39% of Chennai (35.86 Lac out of 91.05 lakh), it weaves news into the fabric of millions' daily lives. With 18 editions, including international ones in Dubai and Sri Lanka, The Daily Thanthi's influence resonates globally, cementing its status as a premier publication.</p>
                        </div>
                    </div>
                </div>
                <div className="blog-container sec-content">
                    <div className="newspaper" style={{ transform: `rotateY(${rotationTheHindu}deg)`}} >
                        <div className="front-content">
                            <img src="./images/hinduTamil.png" alt="" />
                            <p>Established in 1878 as a weekly, The Hindu t,......</p>
                            <button onClick={handleTheHindu}>Know more <i><FontAwesomeIcon icon={faArrowUpRightFromSquare} fade style={{color: "#ff0000",}} /></i></button>
                            <div className="lineEffect"></div>
                        </div>
                        <div className="back-content">
                        <i onClick={handleTheHindu} style={{cursor:"pointer"}}><FontAwesomeIcon icon={faCircleXmark} /></i>
                        <p>Established in 1878 as a weekly, The Hindu transitioned to a daily in 1889, experiencing continuous growth since. A digital pioneer, it launched India's first online edition in 1995, with its website, app, and e-paper attracting a growing audience.
                        Printed in 19 centers nationwide, The Hindu adapts content for diverse regions, producing over 40 versions daily, ensuring tailored news and advertising for varied readership. This dynamic approach keeps The Hindu at the forefront, meeting the evolving needs of its audience both in print and online.</p>
                        </div>
                    </div>
                </div>
                <div className="blog-container third-content">
                    <div className="newspaper" style={{ transform: `rotateY(${rotation}deg)`}} >
                        <div className="front-content">
                            <img src="./images/timesOfIndia.png" alt="" />
                            <p>The Times of India, founded in 1838, holds the,......</p>
                            <button onClick={handleTimesOfIndia}>Know more <i><FontAwesomeIcon icon={faArrowUpRightFromSquare} fade style={{color: "#ff0000",}} /></i></button>
                            <div className="lineEffect"></div>
                        </div>
                        <div className="back-content">
                        <i onClick={handleTimesOfIndia} style={{cursor:"pointer"}}><FontAwesomeIcon icon={faCircleXmark} /></i>
                            <p>The Times of India, founded in 1838, holds the distinction of being the world's largest-circulating English-language Newspaper. Widely Respected in India, it boasts a massive Readership Nationwide and is owned by Bennett, Coleman & Co. Ltd. 
                            In the Brand Trust Report India 2019, TOI earned the title of the most trusted English Newspaper. With over 50 Editions, a Readership exceeding 7.6 million, and a Circulation surpassing 4.5 million, it stands as a Journalistic Giant. Online, TOI's website attracts over 18 million unique visitors and boasts more than 350 million page views, making it a digital force as well.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_PATH } from '../API';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export const CompanyBill = () => {
  const [formData, setFormData] = useState({
    gstin: "",
    companyName: "",
    color: "",
    billNo: ""
  });

  const [error, setError] = useState("");
  const [bills, setBills] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch data from MySQL when the component loads
    const fetchBills = async () => {
      try {
        const response = await axios.get(`${API_PATH}/client_datas/companybill.php`);
        setBills(response.data);
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      }
    };

    fetchBills();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { gstin, companyName, color, billNo } = formData;

    // Basic validation
    if (!gstin || !companyName || !color || !billNo) {
      setError("Please fill in all fields.");
      return;
    }

    try {
      const response = await axios.post(`${API_PATH}/client_datas/companybill.php`, formData);

      if (response.data.status === 1) {
        alert("Data submitted successfully!");
        setFormData({
            gstin: "",
            companyName: "",
            color: "",
            billNo: ""
          });
        const updatedBills = await axios.get(`${API_PATH}/client_datas/companybill.php`);
        setBills(updatedBills.data);
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      setError("There was an error submitting the form.");
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.post(`${API_PATH}/client_datas/companybill.php`, { id });
      if (response.data.status === 1) {
        // Remove the deleted item from the state
        setBills(bills.filter(bill => bill.id !== id));
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("There was an error deleting the data!", error);
    }
  };

  return (
    <div className="container mt-5">
      <button className="btn btn-outline-primary" onClick={() => navigate(-1)}>
        <FontAwesomeIcon icon={faArrowLeft} /> Back
      </button>
      <h2>Company Bill</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      <form onSubmit={handleSubmit}>
        {/* Form Fields */}
        <div className="mb-3">
          <label htmlFor="gstin" className="form-label">GSTIN Number</label>
          <input
            type="text"
            className="form-control"
            id="gstin"
            name="gstin"
            value={formData.gstin}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="companyName" className="form-label">Company Name</label>
          <input
            type="text"
            className="form-control"
            id="companyName"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3 d-flex align-items-center">
          <div className="flex-grow-1">
            <label htmlFor="color" className="form-label">Color (RGB)</label>
            <input
              type="text"
              className="form-control"
              id="color"
              name="color"
              placeholder="e.g., rgb(255,0,0)"
              value={formData.color}
              onChange={handleChange}
              required
            />
          </div>
          <div
            className="color-box ms-3"
            style={{
              width: "40px",
              height: "40px",
              backgroundColor: formData.color,
              border: "1px solid #ccc"
            }}
          ></div>
        </div>
        <div className="mb-3">
          <label htmlFor="billNo" className="form-label">Bill No Start With</label>
          <input
            type="text"
            className="form-control"
            id="billNo"
            name="billNo"
            value={formData.billNo}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Submit</button>
      </form>

      {/* Display Table */}
      <h3 className="mt-5">Company Bills</h3>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>GSTIN</th>
            <th>Company Name</th>
            <th>Color</th>
            <th>Bill No</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {bills.map(bill => (
            <tr key={bill.id}>
              <td>{bill.id}</td>
              <td>{bill.gstin}</td>
              <td>{bill.company_name}</td>
              <td>
                <div
                  style={{
                    backgroundColor: bill.color,
                    width: "40px",
                    height: "40px",
                    border: "1px solid #ccc"
                  }}
                ></div>
              </td>
              <td>{bill.bill_no}</td>
              <td>
                <button
                  className="btn btn-danger"
                  onClick={() => handleDelete(bill.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

import './CSS/contact.css';

export const Contact = () => {
    return(
        <div>
            <iframe 
                title="Sri Sairam Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.947534187894!2d80.1724251!3d13.0390116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266cd9fe353e7%3A0x17d892c49bc1e287!2sSri%20Sairam%20Advertising!5e0!3m2!1sen!2sin!4v1703655163274!5m2!1sen!2sin" 
                width="600" 
                height="450" 
                className='map'
                style={{border:"0"}}
                allowfullscreen="" 
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    )
};
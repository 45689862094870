import React, { useState, useEffect } from "react";
import Axios from "axios";
import { API_PATH } from '../API';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export const BillDetails = () => {
    const [billingData, setBillingData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const navigate = useNavigate();

    useEffect(() => {
        fetchBillingData();
    }, []);

    const fetchBillingData = async () => {
        try {
            const response = await Axios.get(`${API_PATH}/client_datas/bill_details.php`);
            setBillingData(response.data);
        } catch (error) {
            console.error("Error fetching billing data:", error);
        }
    };

    const handleEdit = (id) => {
        navigate(`/edit_bill/${id}`);
    };

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this record?")) {
            try {
                const response = await Axios.delete(`${API_PATH}/client_datas/bill_details.php?id=${id}`);
                if (response.data.message === "Record deleted successfully") {
                    setBillingData(billingData.filter(bill => bill.id !== id)); // Update the state to remove the deleted record
                    alert("Record deleted successfully");
                } else {
                    alert("Failed to delete record");
                }
            } catch (error) {
                console.error("Error deleting record:", error);
                alert("An error occurred while trying to delete the record");
            }
        }
    };

    // Pagination Logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = billingData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(billingData.length / itemsPerPage);

    const handleClick = (pageNumber) => setCurrentPage(pageNumber);

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPageNumbersToShow = 5;
        let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbersToShow / 2));
        let endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);

        if (endPage - startPage < maxPageNumbersToShow - 1) {
            startPage = Math.max(1, endPage - maxPageNumbersToShow + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
                    <button onClick={() => handleClick(i)} className="page-link">{i}</button>
                </li>
            );
        }

        return pageNumbers;
    };

    return (
        <div className="mt-3">
            <button className="btn btn-outline-primary" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} /> Back
            </button>
            <h2 className="text-center mb-4">Bill Details</h2>
            <table className="table table-striped table-bordered table-hover">
                <thead className="thead-dark">
                    <tr className="text-center">
                        <th>Sl.no</th>
                        <th>Client Name</th>
                        <th>Phone Number</th>
                        <th>Bill No</th>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>CGST</th>
                        <th>SGST</th>
                        <th>Total Amount</th>
                        <th>Company Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((bill, index) => (
                        <tr key={bill.id}>
                            <td className="text-center">{index + 1}</td>
                            <td>{bill.client_name}</td>
                            <td className="text-center">{bill.phone_number}</td>
                            <td className="text-center">{bill.bill_no}</td>
                            <td className="text-center">{bill.date}</td>
                            <td className="text-right">{bill.amount}</td>
                            <td className="text-right">{bill.cgst}</td>
                            <td className="text-right">{bill.sgst}</td>
                            <td className="text-right">{bill.total_amount}</td>
                            <td>{bill.company_name}</td>
                            <td className="text-center d-flex justify-content-evenly">
                                <button className="btn btn-warning btn-sm" onClick={() => handleEdit(bill.id)}>Edit</button>
                                <button className="btn btn-danger btn-sm ml-2" onClick={() => handleDelete(bill.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {/* Pagination */}
            <nav>
                <ul className="pagination justify-content-center">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handleClick(currentPage - 1)}>Previous</button>
                    </li>
                    {renderPageNumbers()}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handleClick(currentPage + 1)}>Next</button>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

import React, { useState, useEffect } from "react";
import './CSS/home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from "react-helmet";

export const Home = () => {

    const countryCode = "+91";
    const phoneNumber = "9500037429";
    const handleWhatsapp = () => {
        const whatsappURl = `https://wa.me/${countryCode}${phoneNumber}`;
        window.open(whatsappURl, '_blank');
    };

    // Testimonial smooth transform
    const [activeDiv, setActiveDiv] = useState(1);

    // Expand and show the text for Newspaper
    const [isExpandedNewspaper, setIsExpandedNewspaper] = useState(false);

    const toggleExpansionNewspaper = () => {
        setIsExpandedNewspaper(!isExpandedNewspaper)
    }

    // Expand and show the text for Grapgic
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded)
    }
    // Expand and show the text for Website
    const [isExpandedWebsite, setIsExpandedWebsite] = useState(false);

    const toggleExpansionWebsite = () => {
        setIsExpandedWebsite(!isExpandedWebsite)
    }
    // Expand and show the text for Digital
    const [isExpandedDigital, setIsExpandedDigital] = useState(false);

    const toggleExpansionDigital = () => {
        setIsExpandedDigital(!isExpandedDigital)
    }



    // slect the state for newspaper logo
    const [selectedOption, setSelectedOption] = useState(null);

    const options = [
        { value: 'image1', label: 'Tamil Nadu', images: ['./images/theHindu.png', './images/timesOfIndia.png', './images/indiaExpress.png', './images/dtNext.png', './images/trinityMirror.png', '/images/chronicle.png', './images/dinathanthi.png', './images/dinamalar.png', './images/dinamani.png', './images/dinakaran.png', './images/malaimalar.png', './images/hinduTamil.png',] },
        { value: 'image2', label: 'Andhra', images: ['./images/eenadu.png', './images/shakshi.png', './images/dinamalar.png', './images/dinamani.png', './images/dinathanthi.png'] },
        { value: 'image3', label: 'Kerala', images: ['./images/chronicle.png', './images/dinakaran.png', './images/dinamalar.png', './images/dinamani.png', './images/dinathanthi.png'] }
    ];

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        const selectedOption = options.find(option => option.value === selectedValue);
        setSelectedOption(selectedOption);
    };


    const words = ["Professionally", "Officially", "Actively", "Trustfully"];
    const [currentWordIndex, setCurrentWordIndex] = useState(0);

    useEffect(() => {


        AOS.init();


        // Testimonial
        const interval = setInterval(() => {
            setActiveDiv((prevDiv) => (prevDiv % 3) + 1);
        }, 5000);

        // slect the state for newspaper logo
        setSelectedOption(options[0]);

        document.getElementById('yourButtonId').click();

        const intervalId = setInterval(() => {
            setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        }, 2000); // Adjust the interval as needed (e.g., 2000 milliseconds for a 2-second interval)

        return () => { clearInterval(intervalId, interval) }; // Cleanup the interval on component unmount

    }, [words.length]);


    return (
        <div style={{ overflow: "hidden" }}>
            <Helmet>
                <title>Sri Sairam Advertising</title>
                <meta name="description" content="Explore advertising excellence in Chennai with Sri Sairam Advertising, your unmatched partner for impactful campaigns. Elevate your brand effectively with us."/>
                <meta name="keywords" content="newspaper advertising, newspaper ad booking, ad in newspaper, give advertisement in newspaper, news ads in online, Property Buying and Selling, Death/ Birth Certificate Notices, Bar Council Enrolment"/>
                <meta property="og:title" content="Unlocking Your Business Potential" />
                <meta property="og:url" content="https://srisairamadvertising.com/" />
                <meta property="og:description" content="At Sri Sairam Adevertising, we specialize in a range of services designed to enhance your business's visibility and success. Whether you're looking to reach a broader audience through traditional newspaper ads or seeking cutting-edge digital marketing solutions, we have the expertise to help you thrive" />
                <meta property="og:image" content="https://srisairamadvertising.com/images/Twitterpost.png"/>
                <link rel="canonical" href="https://srisairamadvertising.com/" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
                <div className="text-slide">
                    <p><span className="sliding-words">{words[currentWordIndex]}</span><br /> We'll Help You Make <br />a Big Impact <br />Online.</p>
                    <h6>Meet our fabulous team to create your <br /> Business needs</h6>
                    <button onClick={handleWhatsapp} className="fw-bolder" style={{ backgroundColor: "rgba(50, 180, 74, 1)", color: "white", border: "none" }}>Let's Discuss</button>
                </div>
                <div className="empty-div"></div>
                <div class="carousel-inner">
                    <div class="carousel-item active" data-bs-interval="2000">
                        <img src="./images/slide1.png" class="d-block w-100" alt="slide" />
                    </div>
                    <div class="carousel-item" data-bs-interval="2000">
                        <img src="./images/slide2.png" class="d-block w-100" alt="slide" />
                    </div>
                    <div class="carousel-item" data-bs-interval="2000">
                        <img src="./images/slide3.png" class="d-block w-100" alt="slide" />
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button id="yourButtonId" class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
            {/* Sliding from left to right text */}
            <div className="moving-container">
                <p className="moving-text"> <span> Digital marketing that drives results.</span><span>Connecting you to your audience in the digital age.</span> <span>Transforming your online presence with innovative solutions.</span><span>Elevate your brand in the digital world.</span><span>Leading the way in digital marketing excellence.</span></p>
            </div>
            {/* Category content */}
            <div>
                <div className="category-container">
                    <p className="topic-head">Industries We Work With</p>
                    <div className="single-category">
                        <div className="single-div">
                            <div className="graphic-div">
                                <img src="./images/category2.png" alt="Graphic" />
                                <p>Newspaper Advertising</p>
                                <a href="#newspaper">Learn more <i><FontAwesomeIcon icon={faAnglesRight} /></i></a>
                            </div>
                        </div>
                        <div className="single-div">
                            <div className="newspaper-div">
                                <img src="./images/category4.png" alt="Graphic" />
                                <p>Website Designing</p>
                                <a href="#website">Learn more <i><FontAwesomeIcon icon={faAnglesRight} /></i></a>
                            </div>
                        </div>
                        <div className="single-div">
                            <div className="graphic-div">
                                <img src="./images/category1.png" alt="Graphic" />
                                <p>Graphic Designing</p>
                                <a href="#graphic">Learn more <i><FontAwesomeIcon icon={faAnglesRight} /></i></a>
                            </div>
                        </div>
                        <div className="single-div fouth-div">
                            <div className="newspaper-div">
                                <img src="./images/category3.png" alt="Graphic" />
                                <p>Digital Advertising</p>
                                <a href="#digital">Learn more <i><FontAwesomeIcon icon={faAnglesRight} /></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Newspapers logo Slider */}
            <div className="newspaper-container">
                <p className="topic-head">Newspapers</p>
                <div className="sliding-img-container">
                    <img className="slide-img" src="./images/theHindu.png" alt="The Hindu" />
                    <img className="slide-img" src="./images/timesOfIndia.png" alt="The Hindu" />
                    <img className="slide-img" src="./images/indiaExpress.png" alt="The Hindu" />
                    <img className="slide-img" src="./images/dtNext.png" alt="The Hindu" />
                    <img className="slide-img" src="./images/trinityMirror.png" alt="The Hindu" />
                    <img className="slide-img" src="./images/chronicle.png" alt="The Hindu" />
                    <img className="slide-img" src="./images/dinathanthi.png" alt="The Hindu" />
                    <img className="slide-img" src="./images/dinamalar.png" alt="The Hindu" />
                    <img className="slide-img" src="./images/dinamani.png" alt="The Hindu" />
                    <img className="slide-img" src="./images/dinakaran.png" alt="The Hindu" />
                    <img className="slide-img" src="./images/malaimalar.png" alt="The Hindu" />
                    <img className="slide-img" src="./images/hinduTamil.png" alt="The Hindu" />
                    <img className="slide-img" src="./images/thinaboomi.png" alt="Thinaboomi" />
                </div>
                <div className="choose-state">
                    <p className="topic-head">Select State</p>
                    <div className="custom-select">
                        <select onChange={handleSelectChange} value={selectedOption ? selectedOption.value : ''} >
                            <option value="" disabled>Choose</option>
                            {options.map(option => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div>
                    {selectedOption && (
                        <div className="image-container">
                            {selectedOption.images.map((imageUrl, index) => (
                                <img key={index} src={imageUrl} alt={`Images ${index + 1}`} />
                            ))}
                        </div>
                    )}
                </div>
            </div>
            {/* Text Content for all Category's */}
            <div>
                {/* Newspaper Advertising */}
                <div id="newspaper" className="website-box">
                    <div data-aos="fade-left"
                        data-aos-delay="300"
                        data-aos-easing="ease-in-sine" className="website-text-container">
                        <h5>Newspaper</h5>
                        <h6>Advertising</h6>
                        <p><div className={`website-text ${isExpandedNewspaper ? 'website-expanded' : ''}`}> <li>With over 35 years of experience, we specialize in newspaper advertising, a time-tested and highly effective form of traditional advertising. Targeting the vast readership of newspapers ensures your message reaches a broad audience in just one day.</li>  <br />

                            <li>Newspaper advertising encompasses three distinct models. The first is the Main Display, which, while slightly more expensive, offers unparalleled visibility. For those seeking a more cost-effective option, the Classified Page is a popular choice. Within the Classifieds, there are two primary formats: Classified Display and Classified Line Ads.</li>  <br />

                            <li>The Classified Page is diverse, featuring numerous categories catering to a wide range of advertising needs. From job postings (Situation Vacant) to property transactions (Property Buying/Selling, Rentals), education announcements, document-related notices (Document Missing, Certificate Missing), change of name announcements, and public notices, we cover it all.</li>  <br />

                            <li>We understand the importance of selecting the right newspaper, categories, and publishing days for your advertisements. Our expertise ensures that your ad not only stands out but also delivers the desired results. Whether it's business promotions, car rentals, buying or selling, our newspaper advertising service provides a comprehensive solution for your advertising needs.</li>  <br />

                            <li>Choose newspaper advertising for its proven effectiveness, and let us guide you in making the correct selections for your ads. We're here to ensure your message reaches the right audience, generating the impact you desire.</li></div><button onClick={toggleExpansionNewspaper}>{isExpandedNewspaper ? 'view less' : 'view more'}</button> </p>
                    </div>
                    <div className="graphic-img">
                        <img data-aos="zoom-in" src="./images/newspaper.png" alt="Graphic" />
                    </div>
                </div>
                {/* Graphic Design */}
                <div id="graphic" className="graphic-box">
                    <div className="graphic-img">
                        <img data-aos="zoom-in" src="./images/graphic.png" alt="Graphic" />
                    </div>
                    <div data-aos="fade-right"
                        data-aos-delay="300"
                        data-aos-easing="ease-in-sine" className="graphic-text-container">
                        <h5 >Graphic</h5>
                        <h6>Designing</h6>
                        <p><div className={`graphic-text ${isExpanded ? 'graphic-expanded' : ''}`}><li>We understand the power of visual communication, and our seasoned team of graphic designers is dedicated to bringing your ideas to life. Whether you're a startup or an established enterprise, our graphic design solutions cater to diverse needs.</li> <br />

                            <li>Our graphic design services encompass three primary models, each designed to meet specific requirements. The first is the Premium Graphic Design, which offers a high-end and comprehensive design approach. While this option may have a higher cost, the impact is unparalleled.</li> <br />

                            <li>For those looking for affordability without compromising on quality, our Standard Graphic Design is a popular choice. It strikes the right balance between cost-effectiveness and impactful design solutions. We also offer Custom Graphic Design for those with unique and specific design needs.
                                We take pride in delivering designs that not only meet but exceed client expectations. Our collaborative approach ensures that your vision is translated into visually compelling graphics that resonate with your target audience.</li> <br />

                            <li>Whether you're revamping your brand identity, launching a new product, or enhancing your online presence, our graphic design services are crafted to elevate your visual communication strategy.</li>  <br />

                            <li>Partner with us for a graphic design journey that combines experience, innovation, and a commitment to turning your creative concepts into visually stunning realities. Let your brand's visual identity speak volumes with our expert graphic design solutions.</li></div><button onClick={toggleExpansion}>{isExpanded ? 'view less' : 'view more'}</button> </p>
                    </div>
                </div>
                {/* Website Design */}
                <div id="website" className="website-box">
                    <div data-aos="fade-left"
                        data-aos-delay="300"
                        data-aos-easing="ease-in-sine" className="website-text-container">
                        <h5 className="websiteH5">Website </h5>
                        <h6>Designing</h6>
                        <p><div className={`website-text ${isExpandedWebsite ? 'website-expanded' : ''}`}><li>We specialize in web development, delivering cutting-edge solutions that seamlessly blend innovation, functionality, and user experience. Our commitment to excellence has made us a trusted partner for businesses looking to establish a robust online presence.</li>  <br />

                            <li>Our web development services encompass three distinctive models, each tailored to meet specific requirements. The first is Premium Web Development, offering a comprehensive and high-end approach that guarantees a sophisticated online presence. Though this option may entail a higher investment, the results are unmatched.</li>  <br />

                            <li>For those seeking a balance between affordability and impactful solutions, our Standard Web Development is a popular choice. It ensures a professional and dynamic website without compromising on quality. Additionally, we provide Custom Web Development for those with unique and specific online requirements.
                            We understand that a website is often the first interaction a user has with your brand. Therefore, our team of skilled developers focuses on creating responsive, user-friendly, and visually appealing websites that leave a lasting impression.</li>  <br />

                            <li>Collaboration is at the core of our web development process. We work closely with our clients to understand their goals and translate them into functional and visually stunning websites. Whether you're launching a new business, revamping an existing site, or exploring e-commerce opportunities, our web development services are designed to exceed your expectations.</li> <br />
                            </div><button onClick={toggleExpansionWebsite}>{isExpandedWebsite ? 'view less' : 'view more'}</button> </p>
                    </div>
                    <div className="graphic-img">
                        <img data-aos="zoom-in" src="./images/website.png" alt="Graphic" />
                    </div>
                </div>
                {/* Digital Advertising */}
                <div id="digital" className="graphic-box">
                    <div className="graphic-img">
                        <img data-aos="zoom-in" src="./images/digital.png" alt="Graphic" />
                    </div>
                    <div data-aos="fade-right"
                        data-aos-delay="300"
                        data-aos-easing="ease-in-sine" className="graphic-text-container">
                        <h5 className="digitalH5">Digital</h5>
                        <h6>Advertising</h6>
                        <p><div className={`graphic-text ${isExpandedDigital ? 'graphic-expanded' : ''}`}><li>Welcome to our realm of digital marketing advertising, where we craft strategies that propel businesses into the digital spotlight. With a focus on innovation and effectiveness, our digital marketing solutions are designed to navigate the dynamic landscape of online promotion.</li><br />
                        <li>Our digital marketing advertising services feature three distinct models, each strategically tailored to address specific needs. The first is Premium Digital Marketing, offering a comprehensive and high-impact approach for businesses aiming to make a significant online presence. While this option may come with a higher investment, the results are unparalleled.</li><br />
                        <li>For those seeking a blend of affordability and impactful solutions, our Standard Digital Marketing is a popular choice. It ensures a professional and dynamic online presence without compromising on quality. Additionally, we provide Custom Digital Marketing for those with unique and specific online marketing requirements.</li><br /><li>Understanding the online landscape is crucial, and our team of digital marketing experts is dedicated to creating campaigns that not only reach your target audience but also convert leads into customers.</li></div><button onClick={toggleExpansionDigital}>{isExpandedDigital ? 'view less' : 'view more'}</button> </p>
                    </div>
                </div>
            </div>
            {/* Testimonials Container */}
            <div className="testimonial-container">
                <p className="topic-head">Testimonials</p>
                <div className="testimonial">
                    <div className={`box ${activeDiv === 1 ? 'visible' : 'hidden'}`}>
                        <div className="testmonial-company">
                            <img src="./images/person1.png" alt="Person" />
                            <p><span style={{ color: "rgba(50, 180, 74, 1)" }}>Sri Sairam Advertising</span> Excellent service.  Prompt to take customer's call and guarantee best service.  I am fully satisfied with their services.</p>
                        </div>
                        <img src="./images/testimonials1.png" alt="" />
                    </div>
                    <div className={`secBox ${activeDiv === 2 ? 'visible' : 'hidden'}`}>
                        <div className="testmonial-company">
                            <img src="./images/person2.png" alt="Usa" />
                            <p data-aos="fade-right"><span style={{ color: "rgba(50, 180, 74, 1)" }}>Sri Sairam Advertising</span>is good I recently worked with a newspaper advertising agency for my business and I must say, the experience was very positive. The agency provided valuable insights about the readership demographics and circulation of different newspapers in my target market. This gave me a good understanding of which publications would be effective for my advertising campaign.</p>
                        </div>
                        <img src="./images/testimonials2.png" alt="" />
                    </div>
                    <div className={`thirdBox ${activeDiv === 3 ? 'visible' : 'hidden'}`}>
                        <div className="testmonial-company">
                            <img src="./images/person3.png" alt="amal" />
                            <p data-aos="fade-right"> <span style={{ color: "rgba(50, 180, 74, 1)" }}>Sri Sairam Advertising</span> Very nice approach friendly move and no doubt they are doing well.Any newspaper put the add very good.</p>
                        </div>
                        <img src="./images/testimonials3.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};
import React from "react";
import { Route, Routes } from "react-router-dom";
import { Newspaper } from "./Newspaper";
import { Digital } from "./Digital";
import { WebDesign } from "./WebDesign";
import { Graphic } from "./Graphic";
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './CSS/service.css';
// import { Link } from 'react-router-dom';

export const Service = () => {

useEffect(() => {
        AOS.init();
    },[]);

    return(
        <div>
            <Routes>
                <Route path="newspaper" element={<Newspaper/>}/>
                <Route path="digital" element={<Digital/>} />
                <Route path="webDesign" element={<WebDesign/>}/>
                <Route path="graphic" element={<Graphic/>} />
            </Routes>
            {/* <div className="slideing-service">
                <div className="service-left" data-aos="fade-right"
                    data-aos-anchor="#example-anchor"
                    data-aos-offset="500"
                    data-aos-duration="500"><Link to='/service/newspaper' style={{textDecoration:"none", color:"white"}}>Newspaper Advertising</Link></div>
                <div className="service-right" data-aos="fade-left"
                    data-aos-anchor="#example-anchor"
                    data-aos-offset="500"
                    data-aos-duration="500" data-aos-delay="500"><Link to='/service/digital' style={{textDecoration:"none", color:"white"}}>Digital Advertising</Link></div>
                <div className="service-left" data-aos="fade-right"
                    data-aos-anchor="#example-anchor"
                    data-aos-offset="500"
                    data-aos-duration="500" data-aos-delay="1000"><Link to='/service/webDesign' style={{textDecoration:"none", color:"white"}}>Web Advertising</Link></div>
                <div className="service-right" data-aos="fade-left"
                    data-aos-anchor="#example-anchor"
                    data-aos-offset="500"
                    data-aos-duration="500" data-aos-delay="1500"><Link to='/service/graphic' style={{textDecoration:"none", color:"white"}}>Graphic Advertising</Link></div>
                </div> */}
        </div>
    );
};
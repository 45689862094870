import React from "react";
import './CSS/footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import Facebook from './footer-images/facebook.png';
import Instagram from './footer-images/instagram.png';
import Whatsapp from './footer-images/whatsapp.png';
// import XSocial from './footer-images/x-social.png';
// import Linkdin from './footer-images/linkedin.png';
import Logo from './category-images/logo.png';
import { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';


const generateCaptcha = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const captchaLength = 6;
    let captcha = '';

    for (let i = 0; i < captchaLength; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        captcha += characters[randomIndex];
    }

    return captcha;
};

export const Footer = () => {
    

    const [formData, setFormData] = useState({ subject: "", body: "" });

    const validateForm = (event) => {
        const { subject, body } = formData;
        window.location.href = `mailto:sai29ads@gmail.com?subject=${subject}&body=${body}`;
        event.preventDefault();
        setTimeout(() => {
            window.location.reload();
        }, 500);
        return false;
    };

    const handleInputMailChange = (e) => {
        const { id, value } = e.target;

        if (id === "name") {
            setFormData((prevData) => ({ ...prevData, name: value }));
        } else if (id === "phoneNumber") {
            setFormData((prevData) => ({ ...prevData, phoneNumber: value }));
        } else if (id === "body") {
            setFormData((prevData) => ({ ...prevData, body: value }));
        }

        const subject = `${formData.name} - ${formData.phoneNumber}`;
        setFormData((prevData) => ({ ...prevData, subject }));

    };

    const [captcha, setCaptcha] = useState(generateCaptcha());
    const [userInput, setUserInput] = useState('');

    const regenerateCaptcha = () => {
        setUserInput('');
        setCaptcha(generateCaptcha());
    };

    const handleInputChange = (event) => {
        setUserInput(event.target.value);
    };

    const handleConfirmation = (event) => {
        if (userInput.trim() === '') {
            alert('Please enter the captcha.');
        }
        else if (userInput === captcha) {
            alert('Captcha is correct!'); // Alert for correct captcha
            validateForm(event);
            regenerateCaptcha();
        } else {
            alert('Incorrect captcha. Please try again.'); // Alert for incorrect captcha
        }
    };

    const [scrollPosition, setScrollPosition] = useState(0);

    const handleNavClick = (path) => {

        const targetScrollPosition = 0;

        window.scrollTo({
            top: targetScrollPosition,
            behavior: 'smooth',
        });

        setTimeout(() => {
            setScrollPosition(targetScrollPosition);
            window.location.href = path;
        }, 500);
    }

    const openFacebook = () => {
        window.open('https://www.facebook.com/people/Sri-Sairam-Advertising/61552011140286', '_blank');
    };

    const openInstagram = () => {
        window.open('https://www.instagram.com/srisairamadvertising', '_blank');
    };

    const countryCode = "+91";
    const phoneNumber = "9500037429"
    const openWhatsapp = () => {
        const whatsappURl = `https://wa.me/${countryCode}${phoneNumber}`;
        window.open(whatsappURl, '_blank');
    };

    useEffect(() => {
        regenerateCaptcha();
    }, []); // Run only once on component mount

    return (
        <div>
            <div className="first-footer">
                <h4>Get in Touch</h4>
                <p>Contact Sri Sairam Advertising for a <br /> 360-degree marketing plan</p>
                <form>
                    <input onChange={handleInputMailChange} id="name" type="text" placeholder="Your Name" required />
                    <input onChange={handleInputMailChange} id="phoneNumber" type="number" placeholder="Your Ph. Number" required />
                    <input onChange={handleInputMailChange} id="body" type="text" placeholder="Your Message....." required />
                </form>
                <div className="captcha-container">
                    <div className="captcha-box"><i style={{opacity:"50%"}}>{captcha}</i></div>
                    <div className="captch-input">
                        <label>
                            Enter Captcha:
                            <input type="text" value={userInput} onChange={handleInputChange} />
                        </label>
                        <button onClick={(event) => handleConfirmation(event)}><FontAwesomeIcon icon={faCheck} /> Submit</button>
                        <button onClick={regenerateCaptcha}><FontAwesomeIcon icon={faRotateRight} /></button>
                    </div>
                </div>
            </div>
            <div className="second-footer">
                <div className="footer-company">
                    <img src={Logo} alt="logo" />
                    <h6>Sri Sairam Advertising</h6>
                    <hr />
                    <p>No.2, Svs Nagar 1st Main Road,<br />
                        Valasaravakkam,<br />
                        Chennai-87 <br />
                        Near Holy Cross School</p>
                </div>
                <div className="footer-details">
                    <div className="footer-text footer-service">
                        <label>Services</label>
                        <ul>
                            <li><span onClick={() => handleNavClick('/service/newspaper')}>Newspaper Advertising</span></li>
                            <li><span onClick={() => handleNavClick('/service/digital')}>Digital Advertising</span></li>
                            <li><span onClick={() => handleNavClick('/service/webDesign')}>Web Designing</span></li>
                            <li><span onClick={() => handleNavClick('/service/graphic')}>Graphic Designing</span></li>
                        </ul>
                    </div>
                    <div className="footer-text footer-quick">
                        <label>Quick Link</label>
                        <ul>
                            <li>Privacy Policy</li>
                            <li>Terms and Conditions</li>
                            <li>Site Map</li>
                            <li>Refund Policy</li>
                        </ul>
                    </div>
                    <div className="footer-text footer-about">
                        <label>About</label>
                        <ul>      
                            <li>About us</li>
                            <li>Team</li>
                            <li><NavLink to="/admin">Our Client</NavLink></li>
                        </ul>
                    </div>
                    <div className="footer-icon">
                        <p>Follow on:</p>
                        <img onClick={openFacebook} src={Facebook} alt="FaceBook" />
                        <img onClick={openInstagram} src={Instagram} alt="Instagram" />
                        <img onClick={openWhatsapp} src={Whatsapp} alt="Whatsapp" />
                        {/* <img src={XSocial} alt="X-SocialMedia" />
                        <img src={Linkdin} alt="Linkedin" /> */}
                    </div>
                </div>
                <div className="footer-sec-icon">
                    <p>Follow on:</p>
                    <img onClick={openFacebook} src={Facebook} alt="FaceBook" />
                    <img onClick={openInstagram} src={Instagram} alt="Instagram" />
                    <img onClick={openWhatsapp} src={Whatsapp} alt="Whatsapp" />
                    {/* <img src={XSocial} alt="X-SocialMedia" />
                    <img src={Linkdin} alt="Linkedin" /> */}
                </div>
                <div className="footer-copyrights">
                    <p>&copy; 2023 sri sairam advertising| <a className="condition" target="_blank" href="https://www.freeprivacypolicy.com/live/15172c8c-4f6a-4754-b474-6c621fa7d13d"> Privacy Policy </a> | <a href="https://www.termsandconditionsgenerator.com/live.php?token=Qg2SREMJbqQ9U5pY9mpMU967cgCnL6vh" target="_blank" className="condition"> Terms & Conditions </a></p>
                </div>
            </div>
        </div>
    );
};
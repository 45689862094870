import './CSS/about.css';
import { Helmet } from "react-helmet";

export const About = () => {
    return(
        <div className="about-bg">
            <Helmet>
                <title>Sri Sairam Advertising - About Us</title>
                <meta name="keywords" content="Newspaper ad posting, digital marketing, web development, brand promotion, online advertising, SEO, social media marketing, PPC, content strategy, responsive web design, custom websites, e-commerce solutions, lead generation, Sri Sairam Advertising."/>
                <meta name="description" content="Boost your brand with Sri Sairam Advertising. We offer newspaper ad posting, digital marketing, and expert web development services."/>
                <meta property="og:title" content="Integrated Advertising Solutions: Print, Digital, Web" />
                <meta property="og:url" content="https://srisairamadvertising.com#/#/about" />
                <meta property="og:description" content="Expand your brand's visibility with targeted newspaper ads in leading publications. Reach your audience effectively!" />
                <meta property="og:image" content={`https://srisairamadvertising.com/images/Card.png`}/>
                <link rel="canonical" href="https://srisairamadvertising.com/#/about" />
            </Helmet>
            <div>
                <div className="about-bg-img"></div>
            </div>
            <div className="about-text">
                <h5>Our unique service</h5>
                <p>Our Robust Infrastructure empowers us to Consistently deliver an exceptional quality of service, earning the trust of the World's largest enterprises. Drawing on our collective experience and Regularly enhancing our skills, We consistently lead the way, providing unparalleled value to our Clients across diverse Industry sectors.</p>
            </div>
            <div className="about-company">
                <img src="./images/about-us.png" alt="About Us" />
                <p>Founded in 1988 by the visionary <span> Mr. M.G. Ramalingam</span>, Sri Sairam Advertising Stands as a Beacon of over <span>35 years</span> of Advertising Excellence. Driven by a Passion for Nurturing Client Growth through Advertising, Mr. Ramalingam utilized his Extensive experience to Offer invaluable Advice on Newspaper advertising. Under his Guidance, an Expert team was Meticulously Crafted, Specializing in <span> Delivering impeccable Media Buying services</span>. With a Profound Understanding of the Media Landscape, we Curate a perfect Media mix for Targeted Communication to the Right Audience. Situated in <span> Valasaravakkam,</span> Chennai, our Strategic Marketing ensures Leads, and our Customized Services Cater to your Unique Needs. Conveniently Located near Holy Cross School, we Prioritize Customer Satisfaction, Earning a Commendable Reputation for our Tailored Advertising Solutions. Whether you're a Large Corporation or a Small Business, our Success-driven Brand Promotion Campaigns await you. Contact us Directly for Personalized Pricing and Discover Attractive Offers Crafted just for you.</p>
            </div>

        </div>
    );
};
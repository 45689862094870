import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_PATH } from '../API';
import './CSS/client.css';
import { Navbar } from './Navbar';

export const ClientData = () => {
    const location = useLocation();
    const { state } = location;
    const [message, setMessage] = useState(state?.successMessage || '');
    const navigate = useNavigate();
    const [ads, setAds] = useState([]);
    const [filteredAds, setFilteredAds] = useState([]);
    const [viewFile, setViewFile] = useState(null);
    const [filters, setFilters] = useState({
        name: '',
        contactNumber: '',
        mailId: '',
        fromDate: '',
        toDate: ''
    });

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const visiblePages = 5;

    const [adminName, setAdminName] = useState(null);

    useEffect(() => {

        // Check session storage for adminName key
        const storedAdminName = sessionStorage.getItem('adminName');
        setAdminName(storedAdminName); // Set adminName in state

        if (!storedAdminName) {
            navigate('/admin');
        }

        // Fetch ads data from server
        const fetchData = async () => {
            try {
                const response = await axios.get(`${API_PATH}/client_datas/client_form.php`);
                console.log(response.data);
                const sortedData = response.data.sort((a, b) => b.id - a.id);

                // Filter ads based on adminName
                if (storedAdminName !== 'Srisairam') {
                    const filtered = sortedData.filter(ad => ad.posted_by === storedAdminName);
                    setAds(filtered);
                    setFilteredAds(filtered);
                } else {
                    setAds(sortedData);
                    setFilteredAds(sortedData);
                }
            } catch (error) {
                console.error('There was an error fetching the ads data!', error);
            }
        };

        fetchData();

        if (message) {
            const timer = setTimeout(() => {
                setMessage('');
                navigate('/client');
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [message, navigate]);



    const handleFileView = (fileUrls) => {
        const fileList = JSON.parse(fileUrls);
        setViewFile(fileList);
    };

    const handleFileButtonClick = (fileUrl) => {
        setViewFile(fileUrl);
    };

    const handleCloseView = () => {
        setViewFile(null);
    };

    const truncateText = (text) => {
        if (!text) return '';
        const words = text.split(' ');
        return words.length > 3 ? `${words.slice(0, 3).join(' ')}...` : text;
    };

    const truncateTextContent = (text) => {
        if (!text) return '';
        const words = text.split(' ');
        return words.length > 1 ? `${words.slice(0, 1).join(' ')}...` : text;
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };

    useEffect(() => {
        const filtered = ads.filter(ad => {
            const matchesName = ad.name.toLowerCase().includes(filters.name.toLowerCase());
            const matchesContact = ad.contact_number.toLowerCase().includes(filters.contactNumber.toLowerCase());
            const matchesMail = ad.mail_id.toLowerCase().includes(filters.mailId.toLowerCase());

            // Parse the JSON array of dates
            let postedOnDates = [];
            try {
                postedOnDates = JSON.parse(ad.posted_on).map(date => new Date(date));
            } catch (error) {
                console.error('Error parsing posted_on dates:', error);
                return false; // Skip this item if there's an error in parsing
            }

            const fromDate = filters.fromDate ? new Date(filters.fromDate) : null;
            const toDate = filters.toDate ? new Date(filters.toDate) : null;

            // Check if any date in the array falls within the selected date range
            const matchesDate = postedOnDates.some(date => (!fromDate || date >= fromDate) && (!toDate || date <= toDate));

            return matchesName && matchesContact && matchesMail && matchesDate;
        });

        setFilteredAds(filtered);
        setCurrentPage(1);
    }, [filters, ads]);

    const currentAds = filteredAds.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const totalPages = Math.ceil(filteredAds.length / itemsPerPage);

    const getVisiblePageNumbers = () => {
        const totalPages = Math.ceil(filteredAds.length / itemsPerPage);
        const startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
        const endPage = Math.min(totalPages, startPage + visiblePages - 1);
    
        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const formatDate = (dateString) => {
        try {
            const dateArray = JSON.parse(dateString);
            if (Array.isArray(dateArray) && dateArray.length > 0) {
                const formattedDates = dateArray.map(date => {
                    const [year, month, day] = date.split('-');
                    const formattedDate = new Date(year, month - 1, day);
                    return formattedDate.toLocaleDateString('en-GB'); // 'en-GB' for day-month-year format
                });
                return formattedDates.join(', ');
            } else {
                return 'Invalid Date';
            }
        } catch (error) {
            console.error('Error parsing date:', error);
            return 'Invalid Date';
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(`${API_PATH}/client_datas/client_form.php`, {
                data: { id }
            });

            if (response.data.status === 1) {
                setMessage(response.data.message);
            } else {
                setMessage('Failed to delete record.');
            }
        } catch (error) {
            setMessage('An error occurred while deleting the record.');
        }
    };
    
    const handleLogout = () => {
        sessionStorage.removeItem('adminName');
        navigate('/admin');
    };

    const handlePrintWhatsAppNumbers = () => {
        const whatsappNumbers = currentAds.map(ad => ad.whatsapp_number).join('\n');
        
        const printWindow = window.open('', '', 'height=400,width=600');
        printWindow.document.write('<html><head><title>Print WhatsApp Numbers</title></head><body>');
        printWindow.document.write('<h1>WhatsApp Numbers</h1>');
        printWindow.document.write('<pre>' + whatsappNumbers + '</pre>');
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    };

    const formatText = (text) => {
        if (!text) return '';
        // Remove brackets
        const formattedText = text.replace(/[\[\]"]/g, '');
        // Truncate text to a maximum of 3 words
        const words = formattedText.split(' ');
        return words.length > 3 ? `${words.slice(0, 3).join(' ')}...` : formattedText;
    };
    

    return (
        <div>
            {adminName === 'Srisairam' ? (
                <Navbar />
            ) : null}
        <div className=" p-3 ">
            <div>
                {message && (
                    <div className="alert alert-success mb-4">
                        {message}
                    </div>
                )}
                {adminName !== 'Srisairam' ? (
                    <div className="d-flex justify-content-between">
                        <button className="btn btn-primary">
                            <NavLink to="/client_form" style={{ color: 'white', textDecoration: 'none' }}>
                                Client Data
                            </NavLink>
                        </button>
                        <div>
                            <button className="btn btn-primary ms-2">
                                <NavLink to="/sign" style={{ color: 'white', textDecoration: 'none' }}>
                                    Digital Sign
                                </NavLink> 
                            </button>
                            <button className="btn btn-primary ms-2">
                                <NavLink to="/billing" style={{ color: 'white', textDecoration: 'none' }}>
                                    Billing
                                </NavLink>
                            </button>
                            <button className="btn btn-secondary ms-2" onClick={handleLogout}>
                                Logout
                            </button>
                        </div>
                    </div>
                ) : null}
                {/* Filter options */}
                <div className="filter-options mt-4 mb-3 d-flex justify-content-between">
                    <input
                        type="text"
                        name="name"
                        placeholder="Filter by Name"
                        value={filters.name}
                        onChange={handleFilterChange}
                        className="form-control mb-2 me-2"
                    />
                    <input
                        type="text"
                        name="contactNumber"
                        placeholder="Filter by Contact Number"
                        value={filters.contactNumber}
                        onChange={handleFilterChange}
                        className="form-control mb-2 me-2"
                    />
                    <input
                        type="text"
                        name="mailId"
                        placeholder="Filter by Mail ID"
                        value={filters.mailId}
                        onChange={handleFilterChange}
                        className="form-control mb-2 me-2"
                    />
                    <label className='form-label mt-2'>From</label>
                    <input
                        type="date"
                        name="fromDate"
                        placeholder="From Date"
                        value={filters.fromDate}
                        onChange={handleFilterChange}
                        className="form-control mb-2 me-2"
                    />
                    <label className='form-label mt-2'>To</label>
                    <input
                        type="date"
                        name="toDate"
                        placeholder="To Date"
                        value={filters.toDate}
                        onChange={handleFilterChange}
                        className="form-control mb-2"
                    />
                </div>

                <table className="table mt-4">
                    <thead>
                        <tr className='text-center'>
                            <th>Slno</th>
                            <th>Name</th>
                            <th>Contact Num</th>
                            <th>Mail ID</th>
                            <th>Whatsapp Numr</th>
                            <th>News Content</th>
                            <th>Brand</th>
                            <th>Category</th>
                            <th>Price</th>
                            <th>Posted On</th>
                            <th>Posted By</th>
                            <th>Address</th>
                            <th>GST</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody> 
                        {currentAds.map((ad, index) => (
                            <tr key={ad.id}>
                                <td className='text-center'>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                <td className="truncate" data-full-text={ad.name}>{truncateText(ad.name)}</td>
                                <td className="truncate" data-full-text={ad.contact_number}>{truncateText(ad.contact_number)}</td>
                                <td className="truncate" data-full-text={ad.mail_id}>{truncateText(ad.mail_id)}</td>
                                <td className="truncate" data-full-text={ad.whatsapp_number}>{truncateText(ad.whatsapp_number)}</td>
                                <td className="truncate">
                                    <button className="btn btn-info btn-sm" onClick={() => handleFileView(ad.news_content)}>
                                        View Files
                                    </button>
                                </td>
                                <td className="truncate" data-full-text={ad.brand}>{formatText(ad.brand)}</td>
                                <td className="truncate" data-full-text={ad.category}>{formatText(ad.category)}</td>
                                <td className="truncate" data-full-text={ad.price}>{truncateText(ad.price)}</td>
                                <td className="truncate" data-full-text={formatDate(ad.posted_on)}> 
                                    {truncateTextContent(formatDate(ad.posted_on))}
                                </td>
                                <td className="truncate" data-full-text={ad.posted_by}>{truncateText(ad.posted_by)}</td>
                                <td className="truncate" data-full-text={ad.address}>{truncateTextContent(ad.address)}</td>
                                <td className="truncate" data-full-text={ad.gst}>{truncateText(ad.gst)}</td>
                                <td>
                                    <button className="btn btn-danger btn-sm" onClick={() => handleDelete(ad.id)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button className="btn btn-success my-3" onClick={handlePrintWhatsAppNumbers}>
                    Print
                </button>
                <div className="pagination d-flex justify-content-center">
                    <button
                        className={`btn btn-secondary me-2`}
                        onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    {getVisiblePageNumbers().map(pageNumber => (
                        <button
                            key={pageNumber}
                            className={`btn ${currentPage === pageNumber ? 'btn-primary' : 'btn-secondary'} me-2`}
                            onClick={() => handlePageChange(pageNumber)}
                        >
                            {pageNumber}
                        </button>
                    ))}
                    <button
                        className={`btn btn-secondary me-2`}
                        onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
                {viewFile && Array.isArray(viewFile) ? (
                    <div className="file-view-modal" onClick={handleCloseView}>
                        <div className="file-view-content" onClick={e => e.stopPropagation()}>
                            <button className="close-btn" onClick={handleCloseView}>×</button>
                            {viewFile.map((file, index) => (
                                <button
                                    key={index}
                                    className="btn btn-link"
                                    onClick={() => handleFileButtonClick(file)}
                                >
                                    {file.split('/').pop()}
                                </button>
                            ))}
                        </div>
                    </div>
                ) : viewFile ? (
                    <div className="file-view-modal" onClick={handleCloseView}>
                        <div className="file-view-content" onClick={e => e.stopPropagation()}>
                            <button className="close-btn" onClick={handleCloseView}>×</button>
                            {viewFile.endsWith('.jpg') || viewFile.endsWith('.png') ? (
                                <img src={`${API_PATH}/client_datas/${viewFile}`} alt="File Viewer" className="file-view-image" />
                            ) : viewFile.endsWith('.pdf') ? (
                                <iframe src={`${API_PATH}/client_datas/${viewFile}`} title="File Viewer" className="file-view-iframe"></iframe>
                            ) : (
                                <p>Unsupported file format</p>
                            )}
                        </div>
                    </div>
                ) : null}

            </div>
        </div>
        </div>
    );
};

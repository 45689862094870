import React, { useState, useEffect } from "react";
import Axios from "axios";
import { API_PATH } from '../API';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export const EditBill = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [billData, setBillData] = useState({
        client_name: '',
        address: '',
        phone_number: '',
        client_gst: '',
        bill_no: '',
        date: '',
        details_of_publication: '',
        rate_details: '',
        amount: '',
        cgst: '',
        sgst: '',
        total_amount: '',
        company_name: ''
    });

    useEffect(() => {
        fetchBillData();
    }, [id]);

    const fetchBillData = async () => {
        try {
            const response = await Axios.get(`${API_PATH}/client_datas/editbill.php?id=${id}`);
            setBillData(response.data);
        } catch (error) {
            console.error("Error fetching bill data:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBillData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('id', id);
        formData.append('client_name', billData.client_name);
        formData.append('address', billData.address);
        formData.append('phone_number', billData.phone_number);
        formData.append('client_gst', billData.client_gst);
        formData.append('bill_no', billData.bill_no);
        formData.append('date', billData.date);
        formData.append('details_of_publication', billData.details_of_publication);
        formData.append('rate_details', billData.rate_details);
        formData.append('amount', billData.amount);
        formData.append('cgst', billData.cgst);
        formData.append('sgst', billData.sgst);
        formData.append('total_amount', billData.total_amount);
        formData.append('company_name', billData.company_name);
    
        try {
            const response = await Axios.post(`${API_PATH}/client_datas/editbill.php`, formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            if (response.data.message === "Record updated successfully") {
                alert("Record updated successfully");
                navigate('/bill_details');
            } else {
                alert("Failed to update record");
            }
        } catch (error) {
            console.error("Error updating record:", error);
            alert("An error occurred while trying to update the record");
        }
    };
    

    return (
        <div className="mt-3">
            <button className="btn btn-outline-primary" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} /> Back
            </button>
            <h2 className="text-center mb-4">Edit Bill</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Client Name:</label>
                    <input
                        type="text"
                        className="form-control"
                        name="client_name"
                        value={billData.client_name}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Address:</label>
                    <textarea
                        className="form-control"
                        name="address"
                        value={billData.address}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Phone Number:</label>
                    <input
                        type="text"
                        className="form-control"
                        name="phone_number"
                        value={billData.phone_number}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Client GST:</label>
                    <input
                        type="text"
                        className="form-control"
                        name="client_gst"
                        value={billData.client_gst}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Bill No:</label>
                    <input
                        type="text"
                        className="form-control"
                        name="bill_no"
                        value={billData.bill_no}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Date:</label>
                    <input
                        type="date"
                        className="form-control"
                        name="date"
                        value={billData.date}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Details of Publication:</label>
                    <textarea
                        className="form-control"
                        name="details_of_publication"
                        value={billData.details_of_publication}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Rate Details:</label>
                    <textarea
                        className="form-control"
                        name="rate_details"
                        value={billData.rate_details}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Amount:</label>
                    <input
                        type="number"
                        step="0.01"
                        className="form-control"
                        name="amount"
                        value={billData.amount}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>CGST:</label>
                    <input
                        type="number"
                        step="0.01"
                        className="form-control"
                        name="cgst"
                        value={billData.cgst}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>SGST:</label>
                    <input
                        type="number"
                        step="0.01"
                        className="form-control"
                        name="sgst"
                        value={billData.sgst}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Total Amount:</label>
                    <input
                        type="number"
                        step="0.01"
                        className="form-control"
                        name="total_amount"
                        value={billData.total_amount}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Company Name:</label>
                    <input
                        type="text"
                        className="form-control"
                        name="company_name"
                        value={billData.company_name}
                        onChange={handleChange}
                    />
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
    );
};

import { useState } from 'react';
import axios from 'axios';
import { API_PATH } from '../API';
import { useNavigate } from 'react-router-dom';

export const AdminLogin = () => {
    const [formData, setFormData] = useState({
        adminName: '',
        password: ''
    });
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_PATH}/client_datas/login.php`, formData);
            if (response.data.status === 1) {
                sessionStorage.setItem('adminName', formData.adminName);    
                navigate('/client'); 
            } else {
                setErrorMessage('Invalid admin name or password.');
            }
        } catch (error) {
            console.error('There was an error during login:', error);
            setErrorMessage('An error occurred. Please try again later.');
        }
    };

    return (
        <div className="container d-flex justify-content-center align-items-center min-vh-100">
            <div className="card p-4 shadow-lg" style={{ maxWidth: '400px', width: '100%' }}>
                <h2 className="text-center mb-4">Sri Sairam Login</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="adminName" className="form-label">Admin Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="adminName"
                            name="adminName"
                            value={formData.adminName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password</label>
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary w-100">Login</button>
                    {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}
                </form>
            </div>
        </div>
    );
};

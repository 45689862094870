import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faCaretDown, faXmark, faBars } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import "./CSS/navbar.css";
import { useState } from 'react';
import CompanyLogo from './category-images/logo.png';
import { Helmet } from "react-helmet";


export const Navbar = () => {
    const [isMobile, setISMobile] = useState(false);

    const [isDropdownOpen, setIsDropdownOpen] = useState();
    const handleNavLinkClick = (e) => {
        setIsDropdownOpen(!isDropdownOpen);
        e.preventDefault();
    };

    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScrollDown = () => {
        const targetScrollPosition = 0;

        window.scrollTo({
            top: targetScrollPosition,
            behavior: 'smooth',
        });
    
        setScrollPosition(targetScrollPosition);
        
    }
    return (
        <div className='position'>
            <Helmet>
                <meta property="og:title" content="Maximize Brand Reach with Top Newspaper Ads" />
                <meta property="og:url" content="https://srisairamadvertising.com/#/service/newspaper" />
                <meta property="og:description" content="Expand your brand's visibility with targeted newspaper ads in leading publications. Reach your audience effectively!" />
                <meta property="og:image" content={`https://srisairamadvertising.com/images/Card.png`}/>
                <link rel="alternate" href="https://srisairamadvertising.com/" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <div className="navication">
                <div className="logo">
                    <div className='company-name'>
                        <img src={CompanyLogo} alt="Sri Sairam Logo" />
                        <p>Sri Sairam Advertising</p>
                    </div>
                    <div className='phone'>
                        <p><i><FontAwesomeIcon icon={faPhone} /></i> +91 95000 37429 / 95000 57429</p>
                        <p><i><FontAwesomeIcon icon={faEnvelope} /></i> sai29ads@gmail.com</p>
                    </div>
                    <button className='mob-menu-icon' onClick={() => setISMobile(!isMobile)}>
                        {isMobile ? <i><FontAwesomeIcon icon={faXmark} /></i> : <i ><FontAwesomeIcon icon={faBars} /></i>}
                    </button>
                </div>
                <div onClick={handleScrollDown} className={isMobile ? 'nav-links-mob' : 'location'} >
                    {/* onClick={() => setISMobile(false)} */}
                    <nav>
                        <ul>
                            <li><NavLink onClick={() => setISMobile(false)} to='/'>Home</NavLink></li>
                            <li className='dropdown'>
                                < NavLink to='/service' onClick={handleNavLinkClick}>Service <FontAwesomeIcon icon={faCaretDown} /></NavLink>
                                {isDropdownOpen && (
                                    <div onClick={() => setISMobile(false)} className='dropdown-content'>
                                        <ul>
                                        <li><NavLink to="/service/newspaper">Newspaper Advertising</NavLink></li>
                                        <li><NavLink to="/service/digital">Digital Marketing</NavLink></li>
                                        <li><NavLink to="/service/webDesign">Web Development</NavLink></li>
                                        <li><NavLink to="/service/graphic">Graphic Designing</NavLink></li>
                                        </ul>
                                    </div>)}
                            </li>
                            <li><NavLink onClick={() => setISMobile(false)} to="/about">About us</NavLink></li>
                            <li><NavLink onClick={() => setISMobile(false)} to="/blog">Blog</NavLink></li>
                            <li><NavLink onClick={() => setISMobile(false)} to="/contact">Contact</NavLink></li>
                        </ul>
                    </nav>
                    <div className='mobile'>
                        <p className='mobile-icon'><span><FontAwesomeIcon icon={faPhone} /></span> +91 95000 37429<br />+91 95000 57429</p>
                        <p><i><FontAwesomeIcon icon={faEnvelope} /></i> sai29ads@gmail.com</p>
                    </div>
                    <hr />
                </div>
                <hr />
            </div>
        </div>
    );
};
import React, { useState, useEffect } from "react";
import './CSS/billing.css';
import axios from "axios";
import { API_PATH } from '../API';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export const Billing = () => {
  const [companies, setCompanies] = useState([]);
  const [bill, setBill] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [adminName, setAdminName] = useState('');
  const [fileUploads, setFileUploads] = useState([]);
  const [signImage, setSignImage] = useState(null);
  const [formData, setFormData] = useState({
    clientName: "",
    address: "",
    phoneNumber: "",
    clientGST: "",
    billNo: sessionStorage.getItem('billNo') || "",
    date: "",
    detailsOfPublication: "",
    rateDetails: [""],
    amount: 0,
    totalAmount: 0
  });

  const [billNoRequired, setBillNoRequired] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyColor, setCompanyColor] = useState("green"); 
  const [companyGSTIN, setCompanyGSTIN] = useState("");
  const [cgst, setCgst] = useState(0);
  const [sgst, setSgst] = useState(0);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch company bills data from backend
    axios.get(`${API_PATH}/client_datas/billing.php`)
      .then(response => {
        setCompanies(response.data);
      })
      .catch(error => {
        console.error("Error fetching data", error);
      });

      // Retrieve company data from session storage
    const storedCompany = JSON.parse(sessionStorage.getItem("selectedCompany"));
    if (storedCompany && storedCompany.color) {
      setCompanyName(storedCompany.company_name || "SRI SAIRAM ADVERTISING");
      setCompanyColor(storedCompany.color || "green");
      setCompanyGSTIN(storedCompany.gstin || "33ADQPR7363G3Z1");
      document.documentElement.style.setProperty('--companyColor', storedCompany.color);
    }
    serialNumber();
  }, []);

  useEffect(() => {
    const storedAdminName = sessionStorage.getItem('adminName');
    if (storedAdminName) {
      setAdminName(storedAdminName);
    }
  }, []);

  useEffect(() => {
    axios.get(`${API_PATH}/client_datas/get_sign.php`)
      .then(response => {
        setFileUploads(response.data);
      })
      .catch(error => {
        console.error('Error fetching file uploads:', error);
      });
  }, []);

  // Check for matching adminName and admin_name and set sign image
  useEffect(() => {
    const matchedFile = fileUploads.find(file => file.admin_name === adminName);
    if (matchedFile) {
      setSignImage(matchedFile.file_path);
    }
  }, [fileUploads, adminName]);

  const serialNumber = () => {
    // Fetch company bills data from backend
    axios.get(`${API_PATH}/client_datas/bills_numbers.php`)
      .then(response => {
        setBill(response.data);
      })
      .catch(error => {
        console.error("Error fetching data", error);
      });
  };

  console.log(bill);

  useEffect(() => {
    if (bill.length > 0 && formData.billNo) {
      let currentBillNo = parseInt(formData.billNo, 10); // Convert to an integer

      // Loop to check and increment billNo if it matches an existing one
      while (bill.some(b => b.bill_no === currentBillNo.toString())) {
        currentBillNo += 1;
      }

      // Update formData.billNo with the new value
      setFormData(prevFormData => ({
        ...prevFormData,
        billNo: currentBillNo.toString(),
      }));
    }
  }, [bill, formData.billNo]);

  const handleCompanySelect = (company) => {
    setSelectedCompany(company);
    setFormData({
      ...formData,
      billNo: company.bill_no,
    });

    // Store selected company data in session storage
    sessionStorage.setItem("selectedCompany", JSON.stringify(company));

    // Update component state with the selected company data
    setCompanyName(company.company_name || "SRI SAIRAM ADVERTISING");
    setCompanyColor(company.color || "green");
    setCompanyGSTIN(company.gstin || "33ADQPR7363G3Z1");
    document.documentElement.style.setProperty('--companyColor', company.color);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  // Handle multiline input in Details of Publication
  const handleDetailsChange = (e) => {
    setFormData({
      ...formData,
      detailsOfPublication: e.target.value
    });
  };

  // Handle dynamic Rate Details input fields
  const handleRateChange = (index, value) => {
    const newRateDetails = [...formData.rateDetails];
    newRateDetails[index] = value;

    setFormData({
      ...formData,
      rateDetails: newRateDetails,
      amount: newRateDetails.reduce((total, rate) => total + parseFloat(rate || 0), 0) // Calculate total amount
    });
  };

  const addRateField = () => {
    setFormData({
      ...formData,
      rateDetails: [...formData.rateDetails, ""]
    });
  };

  const removeRateField = (index) => {
    const newRateDetails = [...formData.rateDetails];
    newRateDetails.splice(index, 1);

    setFormData({
      ...formData,
      rateDetails: newRateDetails,
      amount: newRateDetails.reduce((total, rate) => total + parseFloat(rate || 0), 0)
    });
  };

  // Calculate total with CGST and SGST
  useEffect(() => {
    const cgstValue = (formData.amount * (cgst / 100));
    const sgstValue = (formData.amount * (sgst / 100));
    setFormData((prevFormData) => ({
      ...prevFormData,
      totalAmount: parseFloat(prevFormData.amount) + cgstValue + sgstValue,
    }));
  }, [formData.amount, cgst, sgst]);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.clientName) newErrors.clientName = "Client Name is required";
    if (!formData.date) newErrors.date = "Date is required";
    if (!formData.detailsOfPublication) newErrors.detailsOfPublication = "Details of Publication is required";
    if (formData.rateDetails.length === 0 || formData.rateDetails.some(rate => !rate)) newErrors.rateDetails = "Rate Details are required";
    if (formData.amount <= 0) newErrors.amount = "Amount must be greater than 0";
    if (!cgst) newErrors.cgst = "CGST is required";
    if (!sgst) newErrors.sgst = "SGST is required";
    if (formData.totalAmount <= 0) newErrors.totalAmount = "Total Amount must be greater than 0";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Validate the form before submission
    if (validateForm()) {
      // Prepare the form data
      const data = {
        clientName: formData.clientName,
        address: formData.address,
        phoneNumber: formData.phoneNumber,
        clientGST: formData.clientGST,
        billNo: formData.billNo,
        date: formData.date,
        detailsOfPublication: formData.detailsOfPublication,
        rateDetails: formData.rateDetails,
        amount: formData.amount,
        cgst: cgst,
        sgst: sgst,
        totalAmount: formData.totalAmount,
        companyName: companyName
      };
  
      // Send a POST request to the PHP backend
      axios.post(`${API_PATH}/client_datas/billing.php`, data)
        .then(response => {
          alert("Client Bill submitted successfully!");
  
          // Clear form data
          setFormData({
            clientName: "",
            address: "",
            phoneNumber: "",
            clientGST: "",
            billNo: "",
            date: "",
            detailsOfPublication: "",
            rateDetails: [""],
            amount: 0,
            totalAmount: 0
          });
          setCgst(0);
          setSgst(0);
          serialNumber();
        })
        .catch(error => {
          console.error("Error submitting form", error);
          alert("There was an error submitting the form. Please try again.");
        });
    }
  };
  

  const handleBillNoRadioChange = (e) => {
    const value = e.target.value === "yes";
    setBillNoRequired(value);
    if (!value) {
      setFormData({
        ...formData,
        billNo: "",
      });
    }
  };

  const handleDownloadPDF = () => {
    const input = document.querySelector(".copy");
    html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 210;
        const pageHeight = 295;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        const clientName = formData.clientName || 'client';
        const sanitizedClientName = clientName.replace(/\s+/g, '_');
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
            position = imgHeight - heightLeft; 
            pdf.addPage();
            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
        }

        pdf.save(`${sanitizedClientName}_bill.pdf`);
    });
};


  const handleDownloadJPG = () => {
    const input = document.querySelector(".copy");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");
      const link = document.createElement('a');
      const clientName = formData.clientName || 'client';
      const sanitizedClientName = clientName.replace(/\s+/g, '_');
      link.href = imgData;
      link.download = `${sanitizedClientName}_bill.jpg`;
      link.click();
    });
  };

  return (
    <div>
      <div className="mt-2 d-flex justify-content-between">
        <button className="btn btn-outline-primary" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faArrowLeft} /> Back
        </button>
        <button className="btn btn-primary">
          <NavLink to="/bill_details" style={{ color: 'white', textDecoration: 'none' }}>
            Bill's
          </NavLink>
        </button>
      </div>
    <div className="billing-container">
      <div className="billing-form col-md-5 mt-3">
        <h3>Billing Form</h3>
        {/* Company Selection */}
        <div className="company-selection col-md-6 mt-3">
          <p>Bill Name</p>
          {companies.length > 0 ? (
            companies.map((company, index) => (
              <div key={index} className="form-check">
                <input
                  type="radio"
                  name="selectedCompany"
                  value={company.company_name}
                  className="form-check-input"
                  onChange={() => handleCompanySelect(company)}
                />
                <label className="form-check-label">{company.company_name}</label>
              </div>
            ))
          ) : (
            <p>Loading companies...</p>
          )}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-3 mt-2">
            <label>Client Name:</label>
            <input
              type="text"
              name="clientName"
              className="form-control"
              value={formData.clientName}
              onChange={handleChange}
            />
            {errors.clientName && <p className="text-danger">{errors.clientName}</p>}
          </div>
          <div className="form-group mb-3">
            <label>Address:</label>
            <input
              type="text"
              name="address"
              className="form-control"
              value={formData.address}
              onChange={handleChange}
            />
          </div>
          <div className="form-group mb-3">
            <label>Phone Number:</label>
            <input
              type="text"
              name="phoneNumber"
              className="form-control"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
          </div>
          <div className="form-group mb-3">
            <label>Client GST Number:</label>
            <input
              type="text"
              name="clientGST"
              className="form-control"
              value={formData.clientGST}
              onChange={handleChange}
            />
          </div>
          <div className="form-group mb-3">
            <label>Bill No:</label>
            <div>
              <input
                type="radio"
                name="billNoOption"
                value="yes"
                className="form-check-input me-2"
                onChange={handleBillNoRadioChange}
              /> Yes
              <input
                type="radio"
                name="billNoOption"
                value="no"
                className="form-check-input ms-4 me-2"
                onChange={handleBillNoRadioChange}
              /> No
            </div>
            {billNoRequired && (
              <input
                type="text"
                name="billNo"
                className="form-control mt-2"
                value={formData.billNo}
                onChange={handleChange}
              />
            )}
          </div>
          <div className="form-group mb-3">
            <label>Date:</label>
            <input
              type="date"
              name="date"
              className="form-control"
              value={formData.date}
              onChange={handleChange}
            />
            {errors.date && <p className="text-danger">{errors.date}</p>}
          </div>
          <div className="form-group mb-3">
          <label>Details of Publication:</label>
            <textarea
              name="detailsOfPublication"
              className="form-control"
              value={formData.detailsOfPublication}
              onChange={handleDetailsChange}
              style={{ whiteSpace: 'pre-wrap' }}
            />
            {errors.detailsOfPublication && <p className="text-danger">{errors.detailsOfPublication}</p>}
          </div>
          <div className="form-group mb-3">
            <label>Rate Details:</label>
            {formData.rateDetails.map((rate, index) => (
              <div key={index} className="d-flex align-items-center">
                <input
                  type="number"
                  name={`rateDetail-${index}`}
                  className="form-control me-2"
                  value={rate}
                  onChange={(e) => handleRateChange(index, e.target.value)}
                />
                {formData.rateDetails.length > 1 && (
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => removeRateField(index)}
                  >
                    X
                  </button>
                )}
              </div>
            ))}
            <button
              type="button"
              className="btn btn-primary mt-2"
              onClick={addRateField}
            >
              Add Rate
            </button>
            {errors.rateDetails && <p className="text-danger">{errors.rateDetails}</p>}
          </div>
          <div className="form-group mb-3">
            <label>Amount:</label>
            <input
              type="text"
              name="amount"
              className="form-control"
              value={formData.amount}
              onChange={handleChange}
              readOnly
            />
            {errors.amount && <p className="text-danger">{errors.amount}</p>}
          </div>
          <div className="form-group mb-3">
            <label>CGST (%):</label>
            <input
              type="number"
              name="cgst"
              className="form-control"
              value={cgst}
              onChange={(e) => setCgst(parseFloat(e.target.value))}
            />
            {errors.cgst && <p className="text-danger">{errors.cgst}</p>}
          </div>
          <div className="form-group mb-3">
            <label>SGST (%):</label>
            <input
              type="number"
              name="sgst"
              className="form-control"
              value={sgst}
              onChange={(e) => setSgst(parseFloat(e.target.value))}
            />
            {errors.sgst && <p className="text-danger">{errors.sgst}</p>}
          </div>
          <div className="form-group mb-3">
            <label>Total Amount:</label>
            <input
              type="text"
              name="totalAmount"
              className="form-control"
              value={formData.totalAmount}
              onChange={handleChange}
              readOnly
            />
            {errors.totalAmount && <p className="text-danger">{errors.totalAmount}</p>}
          </div>
          <button type="submit" className="btn btn-success">
            Submit
          </button>
        </form>
        <div className="mt-3">
            <button className="btn btn-primary" onClick={handleDownloadPDF}>PDF</button>
            <button className="btn btn-secondary" onClick={handleDownloadJPG}>JPG</button>
          </div>
      </div>

      <div className="billing-preview col-md-6 border">
        <div className="copy p-3 ">
          <div className="bill">
            <div style={{color: companyColor}} className="bill-header">
                    <div className="d-flex justify-content-between p-2">
                        <div className="text-start head-mail">
                            <p>E-Mail : sai29ads@gmail.com</p>
                            <p>HSN / SAC Code 998363</p>
                            <p>GST No. {companyGSTIN}</p>
                        </div>
                        <div className="align-self-center">
                            <p style={{textDecoration: "underline"}}>CASH / CHEQUE BILL</p>
                        </div>
                        <div className="head-mail">
                            <p>Ph : 044 - 42865172</p>
                            <p>Mob : 95000 57429</p>
                        </div>
                    </div>
                    <div className="head-mail">
                        <h4 className="bill-company" style={{textTransform: "uppercase"}}>{companyName}</h4>
                        <p>All News Paper, All Television & Radio Ad Agency</p>
                        <p className="para-add">HO : No 11C, 1st Main Road, SVS Nagar 4th Street, Valasaravakkam, Chennai-87.</p>
                    </div>
                </div>

              <div className="d-flex justify-content-around mt-3">
                <div>
                  <p><strong style={{color: companyColor}}>Client:</strong> {formData.clientName}</p>
                  <p><strong style={{color: companyColor}}>Address:</strong> {formData.address}</p>
                  <p><strong style={{color: companyColor}}>Phone:</strong> {formData.phoneNumber}</p>
                </div>
                <div>
                  <p><strong style={{color: companyColor}}>Bill No:</strong> {formData.billNo}</p>
                  <p><strong style={{color: companyColor}}>Date:</strong> {new Date(formData.date).toLocaleDateString('en-GB')}</p>
                  <p style={{textTransform: "uppercase"}}><strong style={{color: companyColor}}>GST:</strong> {formData.clientGST}</p>
                </div>
              </div>
              <table className="table text-center mt-3">
                <thead >
                  <tr>
                    <th className="bill-border" style={{color: companyColor}}>S.No</th>
                    <th className="bill" style={{color: companyColor}}>Details of Publication</th>
                    <th className="bill" style={{color: companyColor}}>Rate Details</th>
                    <th className="bill-border" style={{color: companyColor}}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bill-bottom">
                    <td className="bill-border">1</td>
                    <td style={{ whiteSpace: 'pre-wrap' }} className="text-start bill">{formData.detailsOfPublication}</td>
                    <td className="bill">
                      {formData.rateDetails.map((rate, index) => (
                        <div key={index}>{rate}</div>
                      ))}
                    </td >
                    <td className="bill-border">{formData.amount.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
              <div style={{paddingRight: "10px"}} className="text-end head-mail">
                <p><strong style={{color: companyColor}}>CGST:</strong>{cgst}% - ₹{(formData.amount * (cgst / 100)).toFixed(2)}</p>
                <p><strong style={{color: companyColor}}>SGST:</strong>{sgst}% - ₹{(formData.amount * (sgst / 100)).toFixed(2)}</p>
                <p><strong style={{color: companyColor}}>Total:</strong>₹{formData.totalAmount.toFixed(2)}</p>
                <p style={{color: companyColor}}>For {companyName}</p>
                <div className="sign-div">
                    <div className="signimage">
                      {signImage ? (
                        <img className="images-sign" src={`${API_PATH}/client_datas/${signImage}`} alt="Admin Sign" />
                      ) : (
                        <p>Loading signature...</p>
                      )}
                    </div>
                    <p style={{color: companyColor}} className="sign-text">( Admin Sign )</p>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    </div>
  );
};

import { Navbar } from './Component/Navbar';
import './App.css';
import { Routes, Route, HashRouter } from 'react-router-dom';
import { Home } from './Component/Home';
import { Footer } from './Component/Footer';
import { About } from './Component/About';
import { Blog } from './Component/Blog';
import {Service} from './Component/Service';
import {Contact} from './Component/Contact';
import whatsapp from './Component/category-images/whatsapp-logo.png'
import { Helmet } from "react-helmet";
import { ClientData } from './Client/ClientData';
import React, { useState, useEffect } from 'react';
import { ClientForm } from './Client/ClientForm';
import { AdminLogin } from './Client/Login';
import { Register } from './Client/Register';
import { ForgotPassword } from './Client/ForgotPassword';
import { Staff } from './Client/Staff';
import { Billing } from './Client/Billing';
import { CompanyBill } from './Client/CompanyBill';
import { Sign } from './Client/Sign';
import { BillDetails } from './Client/BillDetails';
import { EditBill } from './Client/EditBill';


function App() {

  const [showNavbarFooter, setShowNavbarFooter] = useState(true);
  const hideNavbarFooterPaths = ['#/client', '#/admin', '#/register', '#/forgotpassword', '#/staff', '#/company_bill', '#/billing', '#/sign', '#/bill_details', '#/edit_bill/:id'];

  const handleRouteChange = () => {
    const currentPath = window.location.hash;
    const shouldHide = hideNavbarFooterPaths.some(path => currentPath.startsWith(path));
    setShowNavbarFooter(!shouldHide);
  };

  useEffect(() => {
    handleRouteChange(); // Check on initial render

    window.addEventListener('hashchange', handleRouteChange); // Listen for hash changes
    return () => {
      window.removeEventListener('hashchange', handleRouteChange); // Clean up listener
    };
  }, []);

    const countryCode ="+91";
    const phoneNumber ="9500037429";
    const handleWhatsapp = (e) => {
        const whatsappURl = `https://wa.me/${countryCode}${phoneNumber}`;
        window.open(whatsappURl, '_blank');
        e.preventDefault();
    };

    

  return (
    <div className="App">
      <Helmet>
        <meta name="robots" content="index, follow" />
      </Helmet>
      <HashRouter>
      {showNavbarFooter && <Navbar />}
      {showNavbarFooter && (
        <div onClick={handleWhatsapp} className="whatsapp-logo"><img className="img-fluid" src={whatsapp} alt="logo" /></div>
      )}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path="service/*" element={<Service/>}/>
        <Route path='/about' element={<About />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/admin' element={<AdminLogin/>} />
        <Route path='/register' element={<Register/>} />
        <Route path='/forgotpassword' element={<ForgotPassword/>} />
        <Route path='/client' element={<ClientData/>} />
        <Route path='/client_form' element={<ClientForm/>} />
        <Route path='/staff' element={<Staff/>} />
        <Route path='/billing' element={<Billing/>} />
        <Route path='/company_bill' element={<CompanyBill/>} />
        <Route path='/sign' element={<Sign/>} />
        <Route path='/bill_details' element={<BillDetails/>} />
        <Route path='/edit_bill/:id' element={<EditBill/>} />
      </Routes>
      {showNavbarFooter && <Footer />}
      </HashRouter>
    </div>
  );
}

export default App;
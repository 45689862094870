import React from "react";
import './CSS/newspaper.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faHandPointRight} from "@fortawesome/free-solid-svg-icons";
import Job from './category-images/job.png';
import Business from './category-images/business.png';
import Realestate from './category-images/realestate.png';
import Rent from './category-images/rent.png';
import Education from './category-images/education.png';
import Public from './category-images/public.png';
import Tender from './category-images/tender.png';
import Name from './category-images/name.png';
import Court from './category-images/court.png';
import { Helmet } from "react-helmet";

export const Newspaper = () => {

    const countryCode ="+91";
    const phoneNumber ="9500037429";
    const handleWhatsapp = () => {
        const whatsappURl = `https://wa.me/${countryCode}${phoneNumber}`;
        window.open(whatsappURl, '_blank');
    };
    return(
        <div>
            <Helmet>
                <title>Sri Sairam Advertising - Newspaper</title>
                <meta name="keywords" content="Banking Advertisements, Possession Notices, Name Correction, Council Enrolment, Financial Results, Supreme Court Notices, Public notice ads, Anniversary ads, Obituary ads, Situation vacant ads, Property ads, Property buying ads, Property selling ads, Business offer ads"/>
                <meta name="description" content="Ads across top brands like The Hindu, Times of India & more. Reach your audience effectively!"/>
                <meta property="og:title" content="Maximize Brand Reach with Top Newspaper Ads" />
                <meta property="og:url" content="https://srisairamadvertising.com/#/service/newspaper" />
                <meta property="og:description" content="Expand your brand's visibility with targeted newspaper ads in leading publications. Reach your audience effectively!" />
                <meta property="og:image" content={`https://srisairamadvertising.com/images/Card.png`}/>
                <link rel="canonical" href="https://srisairamadvertising.com/#/service/newspaper" />
            </Helmet>
            <div className="services-background" >
                <div className=" services">
                    <div className="services-bg">
                        <div className="services-container">
                            <p className="service-topic">Situation Vacant</p>
                            <img className="situation-img"  src={Job} alt="Situation Vacant" />
                            <button onClick={handleWhatsapp} className="btn-more"><FontAwesomeIcon icon={faHandPointRight} style={{color:"red"}} /> More details</button>
                        </div>
                    </div>
                    <div className="services-bg">
                        <div className="services-container">
                            <p className="service-topic">Real Estate</p>
                            <img className="img-fluid" src={Realestate} alt="Real Estate" />
                            <button onClick={handleWhatsapp} className="btn-more"><FontAwesomeIcon icon={faHandPointRight} style={{color:"red"}} /> More details</button>
                        </div>
                    </div>
                    <div className="services-bg">
                        <div className="services-container">
                            <p className="service-topic">Rental</p>
                            <img className="img-fluid" src={Rent} alt="Rental" />
                            <button onClick={handleWhatsapp} className="btn-more"><FontAwesomeIcon icon={faHandPointRight} style={{color:"red"}} /> More details</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services-background">
                <div className="services">
                    <div className="services-bg">
                        <div className="services-container">
                            <p className="service-topic">Business offer</p>
                            <img className="img-fluid" src={Business} alt="Business offer" />
                            <button onClick={handleWhatsapp} className="btn-more"><FontAwesomeIcon icon={faHandPointRight} style={{color:"red"}} /> More details</button>
                        </div>
                    </div>
                    <div className="services-bg">
                        <div className="services-container">
                            <p className="service-topic">Education</p>
                            <img className="img-fluid" src={Education} alt="Education" />
                            <button onClick={handleWhatsapp} className="btn-more"><FontAwesomeIcon icon={faHandPointRight} style={{color:"red"}} /> More details</button>
                        </div>
                    </div>
                    <div className="services-bg">
                        <div className="services-container">
                            <p className="service-topic">Public Notice</p>
                            <img className="public-img" src={Public} alt="Public Notice" />
                            <button onClick={handleWhatsapp} className="btn-more"><FontAwesomeIcon icon={faHandPointRight} style={{color:"red"}} /> More details</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services-background">
                <div className="services">
                    <div className="services-bg">
                        <div className="services-container">
                            <p className="service-topic">Tender Notice</p>
                            <img className="tender-img" src={Tender} alt="Tender Notice" />
                            <button onClick={handleWhatsapp} className="btn-more"><FontAwesomeIcon icon={faHandPointRight} style={{color:"red"}} /> More details</button>
                        </div>
                    </div>
                    <div className="services-bg">
                        <div className="services-container">
                            <p className="service-topic">Name change</p>
                            <img className="img-fluid" src={Name} alt="Name change" />
                            <button onClick={handleWhatsapp} className="btn-more"><FontAwesomeIcon icon={faHandPointRight} style={{color:"red"}} /> More details</button>
                        </div>
                    </div>
                    <div className="services-bg">
                        <div className="services-container">
                            <p className="service-topic">Court Notice</p>
                            <img className="court-img" src={Court} alt="Court Notice" />
                            <button onClick={handleWhatsapp} className="btn-more"><FontAwesomeIcon icon={faHandPointRight} style={{color:"red"}} /> More details</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
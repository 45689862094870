import { useState } from 'react';
import axios from 'axios';
import { API_PATH } from '../API';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

export const ForgotPassword = () => {
    const [formData, setFormData] = useState({
        adminName: '',
        newPassword: '',
        confirmPassword: ''
    });
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.newPassword !== formData.confirmPassword) {
            setErrorMessage("Passwords do not match!");
            return;
        }

        try {
            const response = await axios.post(`${API_PATH}/client_datas/forgotpassword.php`, formData);
            if (response.data.status === 1) {
                alert("Password updated successfully!");
                navigate('/client');
            } else {
                setErrorMessage("Failed to update password!");
            }
        } catch (error) {
            console.error("There was an error updating the password!", error);
            setErrorMessage("There was an error updating the password!");
        }
    };

    return (
        <div>
            <button className="btn btn-outline-secondary mb-3" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} /> Back
            </button>
        <div className="d-flex justify-content-center align-items-center vh-100">
            <form className="p-5 border" onSubmit={handleSubmit}>
                <h3 className="text-center mb-4">Forgot Password</h3>
                <div className="mb-3">
                    <label htmlFor="adminName" className="form-label">Admin Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="adminName"
                        name="adminName"
                        value={formData.adminName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="newPassword" className="form-label">New Password</label>
                    <div className="input-group">
                        <input
                            type={showNewPassword ? "text" : "password"}
                            className="form-control"
                            id="newPassword"
                            name="newPassword"
                            value={formData.newPassword}
                            onChange={handleChange}
                            required
                        />
                        <span className="input-group-text" onClick={toggleNewPasswordVisibility}>
                            <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye} />
                        </span>
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="confirmPassword" className="form-label">Confirm New Password</label>
                    <div className="input-group">
                        <input
                            type={showConfirmPassword ? "text" : "password"}
                            className="form-control"
                            id="confirmPassword"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            required
                        />
                        <span className="input-group-text" onClick={toggleConfirmPasswordVisibility}>
                            <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                        </span>
                    </div>
                </div>
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                <button type="submit" className="btn btn-primary w-100">Update Password</button>
            </form>
        </div>
        </div>
    );
};

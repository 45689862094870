import React, { useState } from 'react';
import axios from 'axios';
import { API_PATH } from '../API';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export const Sign = () => {
    // Step 1: Get adminName from session storage and set in useState
    const [adminName, setAdminName] = useState(sessionStorage.getItem('adminName') || '');
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    // Step 2: Handle file input change, ensuring it's a PNG
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type === 'image/png') {
            setSelectedFile(file);
            setError('');  // Clear any previous error
        } else {
            setError('Only PNG files are allowed');
        }
    };

    // Step 3: Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedFile) {
            setError('Please select a PNG file.');
            return;
        }

        // Prepare form data
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('adminName', adminName);  // Optionally send adminName

        try {
            // Send data to PHP via Axios
            const response = await axios.post(`${API_PATH}/client_datas/signature.php`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log(response.data);
            navigate('/client')
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    return (
        <div>
            <button className="btn btn-outline-primary" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} /> Back
            </button>
            <div className="container d-flex justify-content-center align-items-center vh-100">
                <div className="card p-4 shadow-sm">
                    <h3 className="text-center mb-4">Welcome, {adminName}</h3>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <input className="form-control" type="file" accept="image/png" onChange={handleFileChange} />
                        </div>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        <div className="d-grid gap-2">
                            <button className="btn btn-primary" type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faHandPointRight} from "@fortawesome/free-solid-svg-icons";
import './CSS/newspaper.css';
import Website from './category-images/website.png';
import Develop from './category-images/develop.png';
import Comers from './category-images/e-comers.png';
import Static from './category-images/static.png';
import Dynamic from './category-images/dynamic.png';
import { Helmet } from "react-helmet";



export const WebDesign = () => {

    const countryCode ="+91";
    const phoneNumber ="9500037429"
    const handleWhatsapp = () => {
        const whatsappURl = `https://wa.me/${countryCode}${phoneNumber}`;
        window.open(whatsappURl, '_blank');
    };

    return(
        <div>
            <Helmet>
                <title>Sri Sairam Advertising - Web Design</title>
                <meta name="keywords" content="Web design, responsive design, custom websites, UX/UI design, e-commerce solutions, mobile optimization, SEO, CMS integration, graphic design, front-end development, WordPress, Shopify, HTML/CSS, JavaScript, website maintenance, performance optimization, social media integration, digital marketing."/>
                <meta name="description" content="Sri Sairam Advertising: Innovative web design solutions for businesses. Custom websites to showcase brands and drive online success. Expertise in responsive design, e-commerce, and user-friendly experiences."/>
                <meta property="og:title" content="Dynamic Web Design" />
                <meta property="og:url" content="https://srisairamadvertising.com/#/service/webDesign" />
                <meta property="og:description" content="Expand your brand's visibility with targeted newspaper ads in leading publications. Reach your audience effectively!" />
                <meta property="og:image" content={`https://srisairamadvertising.com/images/Card.png`}/>
                <link rel="canonical" href="https://srisairamadvertising.com/#/service/webDesign" />
            </Helmet>
            <div className="services-background">
                <div className=" services">
                    <div className="services-bg">
                        <div className="services-container">
                            <p className="service-topic">Website Designing</p>
                            <img className="website-img" src={Website} alt="Web Development" />
                            <button onClick={handleWhatsapp} className="btn-detail"><FontAwesomeIcon icon={faHandPointRight} style={{color:"red"}} /> More details</button>
                        </div>
                    </div>
                    <div className="services-bg">
                        <div className="services-container">
                            <p className="service-topic">Website Development</p>
                            <img className="media-img" src={Develop} alt="Media Operations" />
                            <button onClick={handleWhatsapp} className="btn-detail"><FontAwesomeIcon icon={faHandPointRight} style={{color:"red"}} /> More details</button>
                        </div>
                    </div>
                    <div className="services-bg">
                        <div className="services-container">
                            <p className="service-topic">Search Engine <br />E-commerce Website </p>
                            <img className="eng-img" src={Comers} alt="Logo Making" />
                            <button onClick={handleWhatsapp} className="btn-more"><FontAwesomeIcon icon={faHandPointRight} style={{color:"red"}} /> More details</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services-background">
                <div className=" services">
                    <div className="services-bg">
                        <div className="services-container">
                            <p className="service-topic">Static Website </p>
                            <img className="static-img" src={Static} alt="Designing Posters" />
                            <button onClick={handleWhatsapp} className="btn-more"><FontAwesomeIcon icon={faHandPointRight} style={{color:"red"}} /> More details</button>
                        </div>
                    </div>
                    <div className="services-bg">
                        <div className="services-container">
                            <p className="service-topic">Dynamic Website</p>
                            <img className="dynamic-img" src={Dynamic} alt="Motion Images" />
                            <button onClick={handleWhatsapp} className="btn-more"><FontAwesomeIcon icon={faHandPointRight} style={{color:"red"}} /> More details</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    // Toggle the navbar collapse
    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = () => {
        sessionStorage.removeItem('adminName');
        navigate('/admin');
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-success">
            <div className="container-fluid d-flex align-items-center ">
                {/* Logo and Company Name */}
                <Link className="navbar-brand d-flex align-items-center" to="/client">
                    <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Logo" width="30" height="30" className="d-inline-block align-top" />
                    <span style={{color: "white"}} className="ms-2">Sri Sairam Advertising</span>
                </Link>

                {/* Custom Hamburger Icon and Cart Icon */}
                <div className="d-flex align-items-center ms-auto">
                    {/* Custom Hamburger Icon */}
                    <button style={{color: "white"}} className="navbar-toggler border-0" type="button" onClick={handleToggle}>
                        <i className={`fas ${isOpen ? 'fa-times' : 'fa-bars'}`}></i>
                    </button>
                </div>

                {/* Navbar Links */}
                <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
                    <ul  className="navbar-nav ms-auto">
                        <li className="nav-item d-none d-lg-block">
                            <Link className="nav-link me-2" to="/cart">
                                <i className="fas fa-shopping-cart"></i>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link style={{color: "white"}} className="nav-link" to="/company_bill">Com-Bill</Link>
                        </li>
                        <li className="nav-item">
                            <Link style={{color: "white"}} className="nav-link" to="/staff">Staff</Link>
                        </li>
                        <li className="nav-item">
                            <Link style={{color: "white"}} className="nav-link" to="/register">Register</Link>
                        </li>
                        <li className="nav-item">
                            <Link style={{color: "white"}} className="nav-link" to="/forgotpassword">Forgot Password</Link>
                        </li>
                        <li className="nav-item" onClick={handleLogout}>
                            <b style={{color: "white", cursor: "pointer"}} className="nav-link">Logout</b>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

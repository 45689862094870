import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faHandPointRight} from "@fortawesome/free-solid-svg-icons";
import './CSS/newspaper.css';
import Seo from './category-images/seo.png';
import Media from './category-images/media.png';
import Engine from './category-images/engine.png';
import Opt from './category-images/opt.png';
import Google from './category-images/google.png';
import Face from './category-images/face.png';
import { Helmet } from "react-helmet";

export const Digital = () => {

    const countryCode ="+91";
    const phoneNumber ="9500037429";
    const handleWhatsapp = () => {
        const whatsappURl = `https://wa.me/${countryCode}${phoneNumber}`;
        window.open(whatsappURl, '_blank');
    };

    return(
        <div>
            <Helmet>
                <title>Sri Sairam Advertising - Digital</title>
                <meta name="keywords" content="Digital marketing, online advertising, social media marketing, SEO, PPC, content marketing, email marketing, digital strategy, data analytics, conversion optimization, lead generation, brand visibility, Sri Sairam Advertising"/>
                <meta name="description" content="Transform your online presence with Sri Sairam Advertising's digital marketing expertise. Drive growth and engagement today!"/>
                <meta property="og:title" content="Strategic Digital Marketing" />
                <meta property="og:url" content="https://srisairamadvertising.com/#/service/digital" />
                <meta property="og:description" content="Expand your brand's visibility with targeted newspaper ads in leading publications. Reach your audience effectively!" />
                <meta property="og:image" content={`https://srisairamadvertising.com/images/Card.png`}/>
                <link rel="canonical" href="https://srisairamadvertising.com/#/service/digital" />
            </Helmet>
            <div className="services-background">
                <div className=" services">
                    <div className="services-bg">
                        <div className="services-container">
                            <p className="service-topic">Search Engine Optimization</p>
                            <img className="seo-img"  src={Seo} alt="SEO" />
                            <button onClick={handleWhatsapp} className="btn-detail"><FontAwesomeIcon icon={faHandPointRight} style={{color:"red"}} /> More details</button>
                        </div>
                    </div>
                    <div className="services-bg">
                        <div className="services-container">
                            <p className="service-topic">Search Media <br /> Marketing</p>
                            <img className="media-img" src={Media} alt="SEO" />
                            <button onClick={handleWhatsapp} className="btn-detail"><FontAwesomeIcon icon={faHandPointRight} style={{color:"red"}} /> More details</button>
                        </div>
                    </div>
                    <div className="services-bg">
                        <div className="services-container">
                            <p className="service-topic">Search Engine <br /> Marketing </p>
                            <img className="eng-img" src={Engine} alt="SEO" />
                            <button onClick={handleWhatsapp} className="btn-more"><FontAwesomeIcon icon={faHandPointRight} style={{color:"red"}} /> More details</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services-background">
                <div className=" services">
                    <div className="services-bg">
                        <div className="services-container">
                            <p className="service-topic">Social media Optimization </p>
                            <img className="opt-img"  src={Opt} alt="SEO" />
                            <button onClick={handleWhatsapp} className="btn-more"><FontAwesomeIcon icon={faHandPointRight} style={{color:"red"}} /> More details</button>
                        </div>
                    </div>
                    <div className="services-bg">
                        <div className="services-container">
                            <p className="service-topic">Google Ads</p>
                            <img className="img-fluid" src={Google} alt="SEO" />
                            <button onClick={handleWhatsapp} className="btn-more"><FontAwesomeIcon icon={faHandPointRight} style={{color:"red"}} /> More details</button>
                        </div>
                    </div>
                    <div className="services-bg">
                        <div className="services-container">
                            <p className="service-topic">Facebook Marketing</p>
                            <img className="img-fluid" src={Face} alt="SEO" />
                            <button onClick={handleWhatsapp} className="btn-more"><FontAwesomeIcon icon={faHandPointRight} style={{color:"red"}} /> More details</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faHandPointRight} from "@fortawesome/free-solid-svg-icons";
import './CSS/newspaper.css';
import FBDesign from './category-images/fb-design.png';
import InstaDesign from './category-images/insta-design.png';
import English from './category-images/english.png';
import Tamil from './category-images/Tamil.jpg';
import { Helmet } from "react-helmet";


export const Graphic = () => {

    const countryCode ="+91";
    const phoneNumber ="9500037429"
    const handleWhatsapp = () => {
        const whatsappURl = `https://wa.me/${countryCode}${phoneNumber}`;
        window.open(whatsappURl, '_blank');
    };

    return(
        <div>
            <Helmet>
                <title>Sri Sairam Advertising - Graphic</title>
                <meta name="keywords" content="Graphic design, branding, logo design, visual identity, illustrations, print design, digital graphics, creative solutions, Adobe Creative Suite, typography, vector artwork, artwork production, mockups, brand consistency, Sri Sairam Advertising"/>
                <meta name="description" content="Elevate your brand with our innovative graphic design services. Let us bring your vision to life!"/>
                <meta property="og:title" content="Creative Graphic Design" />
                <meta property="og:url" content="https://srisairamadvertising.com/#/service/graphic" />
                <meta property="og:description" content="Expand your brand's visibility with targeted newspaper ads in leading publications. Reach your audience effectively!" />
                <meta property="og:image" content={`https://srisairamadvertising.com/images/Card.png`}/>
                <link rel="canonical" href="https://srisairamadvertising.com/#/service/graphic" />
            </Helmet>
            <div className="services-background">
                <div className=" services">
                    <div className="services-bg">
                        <div className="services-container">
                            <p className="service-topic">Facebook Designing</p>
                            <img className="seo-img"  src={FBDesign} alt="SEO" />
                            <button onClick={handleWhatsapp} className="btn-detail"><FontAwesomeIcon icon={faHandPointRight} style={{color:"red"}} /> More details</button>
                        </div>
                    </div>
                    <div className="services-bg">
                        <div className="services-container">
                            <p className="service-topic">Instagram Designing</p>
                            <img style={{maxWidth:"270px"}} src={InstaDesign} alt="SEO" />
                            <button onClick={handleWhatsapp} className="btn-detail"><FontAwesomeIcon icon={faHandPointRight} style={{color:"red"}} /> More details</button>
                        </div>
                    </div>
                    <div className="services-bg">
                        <div className="services-container">
                            <p className="service-topic">English Typing </p>
                            <img style={{maxWidth:"252px"}} src={English} alt="SEO" />
                            <button onClick={handleWhatsapp} className="btn-more"><FontAwesomeIcon icon={faHandPointRight} style={{color:"red"}} /> More details</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services-background">
                <div className=" services">
                    <div className="services-bg">
                        <div className="services-container">
                            <p className="service-topic">Tamil Typing  </p>
                            <img style={{maxWidth:"250px"}}  src={Tamil} alt="SEO" />
                            <button onClick={handleWhatsapp} className="btn-more"><FontAwesomeIcon icon={faHandPointRight} style={{color:"red"}} /> More details</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};